<div class="row noMargin date_chip" [ngStyle]="{borderColor: borderColor}">
  <div class="date_div alignSelfCenter">
    <div class="row noMargin date_sec" [ngStyle]="{backgroundColor: bgColor}">
      <div class="col-12 noPadding text-center date_head" [ngStyle]="{color: textColor}">
        {{session_date}}
      </div>
      <div class="col-12 noPadding text-center primaryText" [ngStyle]="{color: textColor}">
        {{sess_month}}
      </div>

    </div>

  </div>

  <div class="col date_body alignSelfCenter">
    <div class="row noMargin date_details_sec">
      <div class="col-12 noPadding smallText lh">{{sess_day}}</div>
      <div class="col-12 noPadding primaryText lh">{{sess_from_time}} - {{sess_to_time}}</div>
      <div class="col-12 noPadding smallText lh">{{loggedInTimeZone}}</div>
    </div>
  </div>
</div>
