import * as S3 from 'aws-sdk/clients/s3';
import { AWSkeySet } from 'src/app/config/api';

// export let tid = '';
export let import_topic_id = { value: '0' };

export class Utils {
    // this function is use to get Data from S3 by passing full path of the server
    public static aws_file_create(file_string:string){
        var url = '../../../../assets/Bsharp-Logo-regulars.png';
        if(file_string){
            const bucket_get = new S3(
                {
                  accessKeyId: AWSkeySet.AWS_UPLOAD_ACCESS_KEY_ID,
                  secretAccessKey: AWSkeySet.AWS_UPLOAD_SECRET_KEY,
                  region: AWSkeySet.AWS_UPLOAD_REGION
                }
              );
          
              var params_get = {
                Bucket: AWSkeySet.AWS_UPLOAD_BUCKET,
                Key: file_string,
                Expires: 86400
              };
          
              url = bucket_get.getSignedUrl('getObject', params_get);
        }
        return url;
    }

    // this function is use to get Data from S3 by passing full path of the server
    public static aws_file_upload(file_name:string,file_content:any){
      var url = '';
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(file_content.name)[1];
      const now = Date.now(); // Unix timestamp in milliseconds
      const contentType = file_content.type;
      var return_data = []
      // file_name = now + '.' + ext;

      // if(file_name){
        const bucket = new S3(
          {
            accessKeyId: AWSkeySet.AWS_UPLOAD_ACCESS_KEY_ID,
            secretAccessKey: AWSkeySet.AWS_UPLOAD_SECRET_KEY,
            region: AWSkeySet.AWS_UPLOAD_REGION
          }
        );

        const self = this;
        const s3_path = 'qa-infogeon.bsharp.in/31/task_files/'+file_name
        console.log(s3_path)
        const params = {
          Bucket: AWSkeySet.AWS_UPLOAD_BUCKET,
          Key: s3_path,
          Body: file_content,
          ACL: AWSkeySet.AWS_ACL,
          ContentType: contentType
        };
        bucket.upload(params, function (err, data) {
          if (err) {
            console.log('There was an error uploading your file: ', err);
            return_data = err
            // return err;
          }else{
            console.log('Successfully uploaded file.', data);
            // return_data = data;
            return data;
          }
        });
          
      // }
      // return return_data;
    }

    // this function is use to get Data from S3 by passing full path of the server for content html editor
    public static aws_file_create_for_htmlEditor(file_string:string){
      var url = '../../../../assets/Bsharp-Logo-regulars.png';
      if(file_string){
          const bucket_get = new S3(
              {
                accessKeyId: AWSkeySet.AWS_UPLOAD_ACCESS_KEY_ID,
                secretAccessKey: AWSkeySet.AWS_UPLOAD_SECRET_KEY,
                region: AWSkeySet.AWS_UPLOAD_REGION
              }
            );
        
            var params_get = {
              Bucket: AWSkeySet.AWS_UPLOAD_BUCKET,
              Key: file_string,
              Expires: 31536000 // Set to one year (in seconds)
            };
        
            url = bucket_get.getSignedUrl('getObject', params_get);
            // console.log('url-->',url)
      }
      return url;
  }
}