import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { baseUrl } from 'src/app/config/api';
import { CookieService } from 'ngx-cookie-service';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';

@Injectable({
  providedIn: 'root'
})
export class InfogeonClassroomService {

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  httpOptions = {

    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRFToken': this.cookieService.get('csrftoken'),
    }),
    withCredentials: true
  };


  create_session(data): Observable<any> {
    return this.http.post(baseUrl + '/class_room/create_session', data, this.httpOptions);
  }

  module_list(): Observable<any> {
    return this.http.get(baseUrl + '/class_room/module_list', this.httpOptions);
  }

  group_list(): Observable<any> {
    return this.http.get(baseUrl + '/class_room/group_list', this.httpOptions);
  }
  attribute_list(): Observable<any> {
    return this.http.get(baseUrl + '/class_room/attribute_list', this.httpOptions);
  }

  session_name_list(): Observable<any> {
    return this.http.get(baseUrl + '/class_room/session_name_list', this.httpOptions);
  }

  trainers_list(): Observable<any> {
    return this.http.get(baseUrl + '/class_room/trainers_list', this.httpOptions);
  }

  group_users_list(data): Observable<any> {
    return this.http.post(baseUrl + '/class_room/group_users_list', data, this.httpOptions);
  }

  fetch_session_by_id(data): Observable<any> {
    return this.http.post(baseUrl + '/class_room/fetch_session_by_id', data, this.httpOptions);
  }

  participant_attendance_update(data): Observable<any> {
    return this.http.post(baseUrl + '/class_room/participant_attendance_update', data, this.httpOptions);
  }


  attendance_status_update(data): Observable<any> {
    return this.http.post(baseUrl + '/class_room/attendance_status_update', data, this.httpOptions);
  }

  users_list(): Observable<any> {
    return this.http.get(baseUrl + '/class_room/users_list', this.httpOptions);
  }

  add_user_individually(data): Observable<any> {
    return this.http.post(baseUrl + '/class_room/add_user_individually', data, this.httpOptions);
  }

  admin_attendance_update(data): Observable<any> {
    return this.http.post(baseUrl + '/class_room/admin_attendance_update', data, this.httpOptions);
  }

  update_session_status(data): Observable<any> {
    return this.http.post(baseUrl + '/class_room/update_session_status', data, this.httpOptions);
  }

  participants_list(data): Observable<any> {
    return this.http.post(baseUrl + '/class_room/participants_list', data, this.httpOptions);
  }


  trainers_session_list(data): Observable<any> {
    return this.http.post(baseUrl + '/class_room/trainers_session_list', data, this.httpOptions);
  }

  learners_session_list(data): Observable<any> {
    return this.http.post(baseUrl + '/class_room/learners_session_list', data, this.httpOptions);
  }

  learner_enroll(data): Observable<any> {
    return this.http.post(baseUrl + '/class_room/learner_enroll', data, this.httpOptions);
  }




  trainers_session_full_list(data): Observable<any> {
    return this.http.post(baseUrl + '/class_room/trainers_session_full_list' , data, this.httpOptions);
  }

  createLearnLogin(data: any, token: any): Observable<any> {

    // Append 'token' to the parameters
    const params = new HttpParams().append('data', data).append('token', token);

    // Make the GET request with the parameters
    return this.http.get(`${baseUrl}/class_room/create_learn_login`, { params });
  }


  //campaign_list(): Observable<any> {
  //return this.http.get(baseUrl + '/payment/campaign_list', this.httpOptions);
  //}

  get_selected_module_skills_list(data): Observable<any> {
    return this.http.post(baseUrl + '/class_room/get_selected_module_skills_list', data, this.httpOptions);
  }

  all_skills_list(): Observable<any> {
    return this.http.get(baseUrl + '/class_room/all_skills_list', this.httpOptions);
  }
  get_users_by_attribte(data): Observable<any> {
    return this.http.post(baseUrl + '/class_room/get_users_by_attribte', data, this.httpOptions);
  }
  remove_marked_user(data): Observable<any> {
    return this.http.post(baseUrl + '/class_room/remove_marked_user', data, this.httpOptions);
  }
  get_report_type_data(activeModule,sid): Observable<any> {
    return this.http.get(baseUrl + '/class_room/get_report_type_data/'+activeModule+"/"+sid, this.httpOptions);
  }
//modified by geetha
  
  rtm_topic_list(): Observable<any> {
    return this.http.get(baseUrl + '/class_room/rtm_topic_list', this.httpOptions);
  }
  // user_score_update(data): Observable<any> {
  //   return this.http.post(baseUrl + '/class_room/user_score_update', data, this.httpOptions);
  // }
  get_rtm_trainers_list(): Observable<any> {
    return this.http.get(baseUrl + '/class_room/get_rtm_trainers_list',  this.httpOptions);
  }
  last_three_month_session_list(data): Observable<any>{
    return this.http.post(baseUrl + '/class_room/last_three_month_session_list', data, this.httpOptions);
  }
}
