import {
	analyzeAndValidateNgModules
} from '@angular/compiler';
import {
	Component,
	OnInit,
	Output,
	EventEmitter,
	ViewChild, ChangeDetectionStrategy,ChangeDetectorRef
} from '@angular/core';
import {
	TaskManagementService
} from 'src/app/services/task-management.service';
import {
	ExcelServicesService
} from 'src/app/services/excel-services.service';
import {
	NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import {
	FormGroup,
	FormControl
} from '@angular/forms';
import {
	DatePipe
} from '@angular/common';
import {
	MatDialogModule
} from '@angular/material/dialog';
import {
	GlobalConstants
} from 'src/app/global-const';
import {
	NgbModal,
	ModalDismissReasons
} from '@ng-bootstrap/ng-bootstrap';

import * as alertify from 'alertifyjs';
import {
	IgxExpansionPanelComponent
} from "igniteui-angular";
import {
	NgxSpinnerService
} from 'ngx-spinner';
import * as XLSX from 'xlsx';
import {
AWSkeySet,
  s3_file_path
} from 'src/app/config/api';
import { Utils } from 'src/app/config/utils';

import { MatSidenav } from '@angular/material/sidenav';
// AOA : array of array
type AOA = any[][];
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-task-report',
  templateUrl: './task-report.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['../task-reminder/task-reminder.component.scss', '../task-create/task-create.component.css', '../task-view/task-view.component.css','./task-report.component.css','../task-list/task-list.component.css']
})


export class TaskReportComponent implements OnInit {
	consoleViewVar: string = 'consoleView';


isDisabled = true;
	filterData = false;
	model: NgbDateStruct;
	fromDate: any;
	toDate: any;
	taskFilterList=[];
task_type:any;

	range = new FormGroup({
		start: new FormControl(),
		end: new FormControl()
	});

	filter_task_type: any = 0;
	filter_task_due: any = "0";
	filter_task_del_filetr: any = "0";
	filter_task_acc_filetr: any = 0;
	accountList: any;

	date: Date;
	edate: Date;
	dueDateRange = {
		start: 0,
		end: 0
	}


  constructor(private taskService: TaskManagementService,
    private cdRef:ChangeDetectorRef,
		public datepipe: DatePipe,
		private modalService: NgbModal,
		private excelService: ExcelServicesService,
		private spinner: NgxSpinnerService) { }

		getAssignedAccounts() {
	this.cdRef.markForCheck();
		this.taskService.getAssignedAccounts().subscribe(accountList => {
			this.accountList = accountList;
			this.accountdropdownList = this.accountList;
		})
	}




	searchData = {
		"task_type": 0,
		"task_acc_filetr": 0,
		"task_from_date": 0,
		"task_to_date": 0,
		"task_filter_applied": false,
		"to_user": null,
		"by_user": null
	};

  task_filter_applied = false;
  userListDelegate: any;
  userdropdownList = [];
  toMeDropdownSettings: any;
  byMeDropdownSettings: any;
  assignToUserSelect:any[] = []
  assignByUserSelect:any[] = []

  dropdownList = [];
    selectedItems = [];
    dropdownSettings = {};

    userRole:any;


  ngOnInit(): void {
   this.getCompanyTaskType();

	  this.accountDropdownSettings = {
                                  singleSelection: true,
                                  text:"Select Account",
                                  //selectAllText:'Select All',
                                  //unSelectAllText:'UnSelect All',
                                  enableSearchFilter: true,
                                  showCheckbox:true,
                                  labelKey:'account_name',
                                  maxHeight:120,
                                  lazyLoading:true,
                                  primaryKey:'acid',
                                  //searchBy:["acid","account_name"],
                                  classes:"myclass custom-class"
                                };


   this.getAssignedAccounts();

   this.AllUsersForDelegate();

    this.toMeDropdownSettings = {
                                  singleSelection: false,
                                  text:"Assigned To",
                                  selectAllText:'Select All',
                                  unSelectAllText:'UnSelect All',
                                  enableSearchFilter: true,
                                  showCheckbox:true,
                                  labelKey:'itemName',
                                  maxHeight:120,
                                  lazyLoading:true,
                                 // primaryKey:'id',
                                  searchBy:["itemName","email_id"],
                                  classes:"myclass custom-class"
                                };
this.byMeDropdownSettings = {
                                  singleSelection: false,
                                  text:"Assigned By",
                                  selectAllText:'Select All',
                                  unSelectAllText:'UnSelect All',
                                  enableSearchFilter: true,
                                  showCheckbox:true,
                                  labelKey:'itemName',
                                  maxHeight:120,
                                  lazyLoading:true,
                                 // primaryKey:'id',
                                  searchBy:["itemName","email_id"],
                                  classes:"myclass custom-class"
                                };
   this.getLoggedInUserRole();

  }

    ngAfterViewInit() {
     this.cdRef.detectChanges();
      }


   getLoggedInUserRole() {
		this.taskService.getLoggedInUserRole().subscribe(res => {
			this.userRole = res;
		})
	}



    AllUsersForDelegate() {
    this.taskService.getUserList().subscribe(userList => {
      this.userListDelegate = userList;
      this.userdropdownList = this.userListDelegate;
    })
   GlobalConstants.navBarSelect = this.consoleViewVar;
//    console.log(GlobalConstants.navBarSelect);



  }

toUserObj: string[] = [];
byUserObj: string[] = [];
to_array: string[] = [];
by_array: string[] = [];
to_selected:any;
by_selected:any;
dateFrom:any;
dateTo:any;

accountSelect :any[] = [];
accountdropdownList = [];
accountDropdownSettings:any;

  applyFilter() {
  this.cdRef.markForCheck();

   if(this.accountSelect.length == 0){
	   this.filter_task_acc_filetr = 0;
	 }else{
	   this.filter_task_acc_filetr = this.accountSelect[0].acid;
	 }

   if(this.assignToUserSelect){

   var to_user_array = this.assignToUserSelect;
        this.toUserObj = JSON.parse(JSON.stringify(to_user_array));
        const to_arr = Array();
        this.toUserObj.forEach(function(object,index) {
          var comment = object['id'];
           to_arr.push(comment);
        });
        this.to_array = to_arr;
        this.to_selected = this.to_array.toString();


    }

    if(this.assignByUserSelect){

   var by_user_array = this.assignByUserSelect;
        this.byUserObj = JSON.parse(JSON.stringify(by_user_array));
        const by_arr = Array();
        this.byUserObj.forEach(function(object,index) {
          var comment = object['id'];
           by_arr.push(comment);
        });
        this.by_array = by_arr;
        this.by_selected = this.by_array.toString();


    }




		this.task_filter_applied = true;
   if(this.fromDate){
		var from = new Date(this.fromDate);
		var myToday = new Date(from.getFullYear(), from.getMonth(), from.getDate(), 0, 0, 0);
		const fromDateFilter = Math.floor(myToday.getTime()/1000);
	  this.dateFrom = fromDateFilter;
    }
    if(this.toDate){
    var to = new Date(this.toDate);
		var to_day  = new Date(to.getFullYear(), to.getMonth(), to.getDate(), 23, 59, 59);
    const toDateFilter = Math.floor(to_day.getTime()/1000);
    this.dateTo = toDateFilter;
}

		this.searchData = {
			"task_type": Number(this.filter_task_type) ? Number(this.filter_task_type) : 0,
			"task_acc_filetr": Number(this.filter_task_acc_filetr) ? Number(this.filter_task_acc_filetr) : 0,
			"task_from_date": this.dateFrom ? this.dateFrom : null,
			"task_to_date": this.dateTo ? this.dateTo : null,
			"task_filter_applied": this.task_filter_applied,
			"to_user": this.to_array ? this.to_array : null,
		  "by_user": this.by_array ? this.by_array : null
		};
		this.applyFilterAPICall()
	}

	applyFilterAPICall() {
	this.taskFilterList=[]
		const self = this;
			let filterTaskPromise = new Promise((resolve, reject) => {
				this.taskService.getFilterTaskReport(this.searchData).toPromise().then(res => {
					this.taskFilterList = res;
					this.cdRef.markForCheck();
				})
			})
	}


	clearFilter() {
		this.searchData.task_filter_applied = false;
		this.task_filter_applied = false;
		this.filter_task_type = 0;
		this.filter_task_acc_filetr = 0;
		this.fromDate = null;
		this.toDate = null;
		this.assignToUserSelect = null;
		this.assignByUserSelect = null;
		this.accountSelect=[];

	}

	getUnixTimeStam(datearray: any) {
		var DateEpoch = 0
		if (datearray != null && datearray != undefined) {
			var dateString = datearray.year + '/' + datearray.month + '/' + datearray.day;
			var dateData = new Date(dateString);
			let latestDate = this.datepipe.transform(dateData, 'yyyy-MM-dd');
			const dateFormate = new Date(latestDate);
			DateEpoch = dateFormate.getTime() / 1000.0;
		}

		return DateEpoch;
	}



	downloadTaskDetails(taskId) {
		let taskDownloadPromise = new Promise((resolve, reject) => {
			this.taskService.getTaskDownloadData(taskId).toPromise().then(res => {
				 let collection: any[] = [];
    res.forEach(function(item: any) {

        if (item.Image && item.Image != 'No') {
            var UplodedImageFile = `${s3_file_path}/${GlobalConstants.userDetails.cmid}/task_files/task_submit/${item.Image}`;
            item.Image = Utils.aws_file_create(UplodedImageFile);
        }
        if (item.Signature && item.Signature != 'No') {
            var signImageFile = `${s3_file_path}/${GlobalConstants.userDetails.cmid}/task_files/task_submit/${item.Signature}`;
            item.Signature = Utils.aws_file_create(signImageFile);
        }
        collection.push(item);

    });
    this.excelService.exportAsExcelFile(collection, `${taskId}_task`);

				//this.excelService.exportAsExcelFile(res, `${taskId}_task`);
			})
		})
		// this.excelService.exportAsExcelFile(this.excel, 'sample');
	}

		deleteTask() {
		const data = {
			"task_id": this.confirmDeleteId,
			"task_status": 1
		};
		let promise = new Promise((resolve, reject) => {
			this.taskService.myTaskUpdate(data).toPromise().then(res => {
				this.deleteReminder(this.confirmDeleteId);
				this.deleteTaskAssign(this.confirmDeleteId);
				this.deleteTaskAssignUsers(this.confirmDeleteId);
				this.modalService.dismissAll();
				this.applyFilter();
			})
		})
	}
	deleteReminder(task_id) {
		let taskUsersPromise = new Promise((resolve, reject) => {
			this.taskService.getReminders(task_id).toPromise().then(res => {
				if (res.length !== 0) {
					this.updateReminder(task_id);
				}
			})
		})
	}
	updateReminder(task_id) {
		const data = {
			"task_id": task_id,
			"status": 1
		};
		this.taskService.deleteReminder(data)
			.subscribe(
				response => {
					console.log(response);
				},
				error => {
					console.log(error);
				});
	}
	deleteTaskAssign(task_id) {
		const data = {
			"task_id": task_id,
			"task_status": 1
		};
		this.taskService.updateTaskAssign(data)
			.subscribe(
				response => {
					console.log(response);
				},
				error => {
					console.log(error);
				});
	}
	deleteTaskAssignUsers(task_id) {
		const data = {
			"task_id": task_id,
		};
		this.taskService.updateTaskAssignUsers(data)
			.subscribe(
				response => {
					//this.taskService.addNewTaskSubject();
					//this.taskService.updateTaskCountService();
				},
				error => {
					console.log(error);
				});
	}
confirmDeleteId: any;
 deleteOpen(content, task_id) {
		this.confirmDeleteId = task_id
		this.modalService.open(content, {
			ariaLabelledBy: 'modal-basic-title'
		}).result.then((result) => {}, (reason) => {});
	}


 downloadAll() {

    if(this.assignToUserSelect){

   var to_user_array = this.assignToUserSelect;
        this.toUserObj = JSON.parse(JSON.stringify(to_user_array));
        const to_arr = Array();
        this.toUserObj.forEach(function(object,index) {
          var comment = object['id'];
           to_arr.push(comment);
        });
        this.to_array = to_arr;
        this.to_selected = this.to_array.toString();


    }

    if(this.assignByUserSelect){

   var by_user_array = this.assignByUserSelect;
        this.byUserObj = JSON.parse(JSON.stringify(by_user_array));
        const by_arr = Array();
        this.byUserObj.forEach(function(object,index) {
          var comment = object['id'];
           by_arr.push(comment);
        });
        this.by_array = by_arr;
        this.by_selected = this.by_array.toString();


    }

   if(this.fromDate){
		var from = new Date(this.fromDate);
		var myToday = new Date(from.getFullYear(), from.getMonth(), from.getDate(), 0, 0, 0);
		const fromDateFilter = Math.floor(myToday.getTime()/1000);
	  this.dateFrom = fromDateFilter;
    }
    if(this.toDate){
    var to = new Date(this.toDate);
		var to_day  = new Date(to.getFullYear(), to.getMonth(), to.getDate(), 23, 59, 59);
    const toDateFilter = Math.floor(to_day.getTime()/1000);
    this.dateTo = toDateFilter;
   }


		this.searchData = {
			"task_type": Number(this.filter_task_type) ? Number(this.filter_task_type) : 0,
			"task_acc_filetr": Number(this.filter_task_acc_filetr) ? Number(this.filter_task_acc_filetr) : 0,
			"task_from_date": this.dateFrom ? this.dateFrom : null,
			"task_to_date": this.dateTo ? this.dateTo : null,
			"task_filter_applied": this.task_filter_applied,
			"to_user": this.to_array ? this.to_array : null,
		  "by_user": this.by_array ? this.by_array : null
		};
		this.downloadFilterAPICall()
	}

		downloadFilterAPICall() {
		const self = this;
			let filterTaskPromise = new Promise((resolve, reject) => {
				this.taskService.getTaskDownloadFilerData(this.searchData).toPromise().then(res => {
				this.excelService.exportAsExcelFile(res, `download_task`);
				})
			})
	}

 task_type_set:any
	getCompanyTaskType(){
	  const self = this;
		let promise = new Promise((resolve, reject) => {
			this.taskService.getCompanyTaskType().toPromise().then(res => {
			console.log(res)
			this.task_type_set=res;
				//this.cdRef.markForCheck();
			})
		})
	}




}
