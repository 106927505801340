<div class="row noMargin create_container">
    <div class="col-4 noPadding flexClass alignSelfCenter">
        <img class="back_arrow alignSelfCenter pointer" [routerLink]="backUrl"
            src="/assets/task_manager_icons/task_manager_back_arrow.svg" alt="">
        <span class="mainHeading ml-2 alignSelfCenter">Back</span>
    </div>
    <div class="col-8 noPadding flexClass alignSelfCenter" style="justify-content: end;">
        <button *ngIf="userType == 'trainer' && is_lc_session == 0 && sess_status == 0 && session_got_canceled ==0" class="secondaryBtn mr-2 blue_bg" routerLink="/classroom/session_list">Save & Close</button>
        <!-- <button *ngIf="userType == 'trainer' && is_lc_session == 0" class="secondaryBtn mr-2 blue_bg" (click)="downloadUserData()">Download sessions</button> -->
        <button *ngIf="userType == 'trainer' && is_lc_session == 1" class="secondaryBtn mr-2 blue_bg" (click)="downloadLcUserData()">Download sessions</button>
        <button *ngIf="sess_status == 0 && userType == 'trainer' && session_got_canceled ==0" (click)="update_session_status(2, fetchUserList, 'cancel')" class="secondaryBtn mr-2 red_btn">
            Cancel session
        </button>
        <button *ngIf="sess_status == 0 && userType == 'trainer' && session_got_canceled ==0" (click)="update_session_status(3, fetchUserList, 'start')" class="primaryBtn">Start session</button>
        <!--        <button *ngIf="sess_status == 3" (click)="update_session_status(1)" class="primaryBtn">Close session</button>-->
        <button *ngIf="sess_status == 3 && userType == 'trainer' && is_lc_session == 0 &&  elevenfiftyNinePm == false" (click)="openCloseModal(closeSession)" class="primaryBtn">Close & Save</button>
        <!-- to add the pre and post score -->
        <button *ngIf="sess_status == 1 && userType == 'trainer' && is_lc_session == 0 &&  elevenfiftyNinePm == false" (click)="update_session_status(1, fetchUserList, 'points')" class="primaryBtn">Save Changes</button>
        <button *ngIf="sess_status == 3 && userType == 'trainer' && is_lc_session == 1 && session_got_canceled == 0" (click)="openCloseModal(closeSession)" class="primaryBtn">Close session</button>
        <span class="subHeading red_text" *ngIf="sess_status == 2 ">Session cancelled</span>
        <span class="subHeading red_text" *ngIf="(sess_status == 0 && session_got_canceled == 1)">Session expired</span>
        <span class="subHeading red_text" *ngIf="(sess_status == 1) || (sess_status == 3 && elevenfiftyNinePm == true && is_lc_session == 0) || (is_lc_session == 1 && session_got_canceled == 1) ">Session closed</span>
    </div>

    <!-- Body sec -->
    <div class="col 12 noPadding normal_mt sec_body">
        <div class="row noMargin head_sec">
            <div class="img_div">
                <img class="session_img" src="{{file_path}}" onerror="this.src='assets/task_manager_icons/default_img.svg';" alt="">
            </div>
            <div class="col pr-0 pl-2 mob_truncate">
                <div class="row noMargin fw500">
                    <span class="primaryText mob_truncate">{{session_name}}</span>
                </div>
                <div class="row noMargin primaryText session_details truncate" *ngIf="is_lc_session == 0">
                    <span *ngIf="classroom_type == 2" class="onNoff_chip common_chip alignSelfCenter">Online &
                        offline</span>
                    <span *ngIf="classroom_type == 0" class="online_chip common_chip alignSelfCenter">Online</span>
                    <span *ngIf="classroom_type == 1" class="offline_chip common_chip alignSelfCenter">Offline</span>
                    <span *ngIf="!group_name" class="skill_type alignSelfCenter col noPadding truncate">{{module_name
                        }}</span>
                    <span *ngIf="group_name" class="skill_type alignSelfCenter col noPadding truncate">{{module_name }}
                        - {{group_name}}</span>
                </div>
                <!-- for lc -->
                <div class="row noMargin primaryText session_details truncate" *ngIf="is_lc_session == 1">
                    <!-- <span class="onNoff_chip common_chip alignSelfCenter">Online &
                        offline</span> -->
                    <span class="skill_type alignSelfCenter col noPadding truncate">{{module_name
                        }}</span>
                </div>
                <div class="row noMargin">
                    <span class="session_desc mob_truncate">
                        {{module_desc}}
                    </span>
                </div>
            </div>
        </div>
        <div class="row noMargin schedule_sec">
            <app-date-chip [dateData]="time_data"></app-date-chip>

            <div *ngIf="userType == 'learner'" class="each_box text-center alignSelfCenter">
                <div class="primaryText lh">{{trainer_name}}</div>
                <div class="smallText lh category mt-1">Trainer Name</div>
            </div>

            <div class="each_box text-center alignSelfCenter" *ngIf="skill">
                <div class="primaryText lh">{{skill}}</div>
                <div class="smallText lh category mt-1">Category</div>
            </div>
            <div class="each_box text-center alignSelfCenter" *ngIf="sub_skill">
                <div class="primaryText lh">{{sub_skill}}</div>
                <div class="smallText lh category mt-1">Sub-Category</div>
            </div>
            <div *ngIf="userType == 'trainer' && is_lc_session == 0" class="each_box text-center alignSelfCenter">
                <div class="primaryText lh">{{assigned_count}} users</div>
                <div class="smallText lh category mt-1">Assigned</div>
            </div>
            <!-- <div *ngIf="userType == 'trainer' && is_lc_session == 0" class="each_box text-center alignSelfCenter">
                <div class="primaryText lh">{{enrolled_count}} users</div>
                <div class="smallText lh category mt-1">Enrolled</div>
            </div> -->
            <div *ngIf="userType == 'trainer' && is_lc_session == 0" class="each_box text-center alignSelfCenter">
                <div class="primaryText lh">{{attended_count}} users</div>
                <div class="smallText lh category mt-1">Attended</div>
            </div>
            <div *ngIf="userType == 'learner' && is_lc_session == 0" class="status_div alignSelfCenter ml-auto">

                <button class="primaryBtn orange_btn alignSelfCenter" (click)="enrollSession($event)"
                                        *ngIf="user_status == 'enroll_now'">
                                        Enroll now
                                    </button>


                <span *ngIf="user_status == 'completed'" class="primaryText">Completed</span>
                <span *ngIf="user_status == 'enrolled'" class="primaryText enroll">Enrolled</span>
            </div>
            <!-- for lc -->
            <div *ngIf="is_lc_session == 1" class="each_box text-center alignSelfCenter alignSelfCenter">
                <span class="primaryText lh">{{time_data.duration}}</span>
                <div class="smallText lh category mt-1">Duration</div>
            </div>

        </div>
       
        <div class="row noMargin meeting_details">
            <div class="clipboard_snackbar mx-auto">
                <igx-snackbar class="snackbar_text" #snackbar>Copied to clipboard</igx-snackbar>
            </div>

            <div class="col-md-4 col-sm-12 col-12 alignSelfCenter meeting_sec" *ngIf="address">
                <div class="row noMargin">
                    <img class="location_icon alignSelfCenter icon_mr" src="assets/classroom/classroom_icons/location.png" alt="">
                    <span class="smallText col noPadding alignSelfCenter lh icon_mr">
                        {{address}}
                    </span>
                    <img class="location_icon alignSelfCenter icon_mr" (click)="copyText(address)"
                        src="assets/classroom/classroom_icons/copy.png" alt="">
                </div>
            </div>
            <div  class="col-md-4 col-sm-6 col-12 alignSelfCenter location_sec" *ngIf="meeting_link">
                <div class="row noMargin truncate">
                    <img class="location_icon alignSelfCenter icon_mr pointer" src="assets/classroom/classroom_icons/video.png"
                        alt="">
                    <a class="alignSelfCenter col noPadding flexClass truncate"
                        href="{{meeting_link}}" target="_blank">
                        <span class="smallText alignSelfCenter lh icon_mr truncate">
                            {{meeting_link}}
                        </span>
                    </a>
                    <img class="location_icon alignSelfCenter icon_mr pointer" (click)="copyText(meeting_link)"
                        src="assets/classroom/classroom_icons/copy.png" alt="">
                </div>
            </div>
            <div class="col-md-4 col-sm-6 col-12 alignSelfCenter video_sec" *ngIf="geolocation">
                <div class="row noMargin truncate">
                    <img class="location_icon alignSelfCenter icon_mr" src="assets/classroom/classroom_icons/map.png" alt="">
                    <a class="alignSelfCenter col noPadding flexClass truncate"
                        href="{{geolocation}}" target="_blank">
                        <span class="smallText alignSelfCenter lh icon_mr truncate">
                            {{geolocation}}
                        </span>
                    </a>
                    <img class="location_icon alignSelfCenter icon_mr pointer" (click)="copyText(geolocation)"
                        src="assets/classroom/classroom_icons/copy.png" alt="">
                </div>
            </div>
        </div>
        <div class="row noMargin btn_div alignSelfCenter add_user_mob flexClass" *ngIf="userType == 'trainer'">
            <div class="alignSelfCenter" *ngIf="session_got_canceled ==0 && sess_status != 1 && is_lc_session ==1">
                <button class="primaryBtn mr-2 blue_bg add_user_btn flexClass" (click)="createUserModel(userModel)">
                    <img class="add_user_icon alignSelfCenter" src="assets/classroom/classroom_icons/add_user.png" alt="">
                    <span class="alignSelfCenter">Add users</span>
                </button>
            </div>
            <div class="alignSelfCenter" *ngIf="session_got_canceled ==0 && sess_status != 1 && is_lc_session ==1">
                <a href="/lc/add_unregistered_users/{{sid}}">
                    <button class="primaryBtn blue_bg_un add_user_btn flexClass">
                        <img class="add_user_icon alignSelfCenter" src="assets/classroom/classroom_icons/add_user.png" alt="">
                        <span class="alignSelfCenter">Unregistered users</span>
                    </button>
                </a>
            </div>
            <!-- for rtm -->
            <div class="alignSelfCenter" *ngIf="((session_got_canceled ==0 && sess_status != 1)|| (sess_status == 3 && elevenfiftyNinePm == false) || (sess_status == 1 && elevenfiftyNinePm == false)) && is_lc_session == 0">
                <button class="primaryBtn mr-2 blue_bg add_user_btn flexClass" (click)="createUserModel(userModel)">
                    <img class="add_user_icon alignSelfCenter" src="assets/classroom/classroom_icons/add_user.png" alt="">
                    <span class="alignSelfCenter">Add users</span>
                </button>
            </div>
            <div class="alignSelfCenter" *ngIf="((session_got_canceled ==0 && sess_status != 1) || (sess_status== 3  && elevenfiftyNinePm == false) || (sess_status == 1 && elevenfiftyNinePm == false)) && is_lc_session == 0">
                <a href="/lc/add_unregistered_users/{{sid}}">
                    <button class="primaryBtn blue_bg_un add_user_btn flexClass">
                        <img class="add_user_icon alignSelfCenter" src="assets/classroom/classroom_icons/add_user.png" alt="">
                        <span class="alignSelfCenter">Unregistered users</span>
                    </button>
                </a>
            </div>
            <div *ngIf="(sess_status == 3) || (sess_status == 1)">
                <div class="alignSelfCenter" *ngIf="isMobile">
                    <!-- <button class="secondaryBtn red_btn alignSelfCenter ml-3" (click)="attendance_status_update(1)"
                        *ngIf="attendance_status ==0">Close attendance</button> -->
                    <button class="secondaryBtn alignSelfCenter" (click)="attendance_status_update(0)"
                        *ngIf="attendance_status ==1">Open attendance</button>
                </div>
            </div>
        </div>
        <div class="row noMargin participant_header_sec" *ngIf="userType == 'trainer'">
            <div class="col-sm-12 col-6 col-md-4 col-lg-6 col-xl-6 noPadding flexClass">
                <span class="subHeading alignSelfCenter icon_mr">Participants assigned</span>
                <span class="participant_count alignSelfCenter smallText lh">{{assigned_count}}</span>
                <div class="btn_div alignSelfCenter add_user_dt" *ngIf="session_got_canceled ==0 && sess_status != 1 && is_lc_session ==1">
                    <div class="row noMargin">
                        <button class="primaryBtn blue_bg add_user_btn flexClass" (click)="createUserModel(userModel)">
                            <img class="add_user_icon alignSelfCenter" src="assets/classroom/classroom_icons/add_user.png" alt="">
                            <span class="alignSelfCenter">Add users</span>
                        </button>
                        <!-- is_lc_session==1 && -->
                        <div class="alignSelfCenter">
                            <a href="/lc/add_unregistered_users/{{sid}}">
                                <button class="primaryBtn  blue_bg_un add_user_btn flexClass">
                                    <img class="add_user_icon alignSelfCenter" src="assets/classroom/classroom_icons/add_user.png" alt="">
                                    <span class="alignSelfCenter">Unregistered users</span>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="btn_div alignSelfCenter add_user_dt" *ngIf="((session_got_canceled ==0 && sess_status != 1) || (sess_status== 3  && elevenfiftyNinePm == false) || (sess_status== 1  && elevenfiftyNinePm == false)) && is_lc_session ==0">
                    <div class="row noMargin">
                        <button class="primaryBtn blue_bg add_user_btn flexClass" (click)="createUserModel(userModel)">
                            <img class="add_user_icon alignSelfCenter" src="assets/classroom/classroom_icons/add_user.png" alt="">
                            <span class="alignSelfCenter">Add users</span>
                        </button>
                        <!-- is_lc_session==1 && -->
                        <div class="alignSelfCenter">
                            <a href="/lc/add_unregistered_users/{{sid}}">
                                <button class="primaryBtn  blue_bg_un add_user_btn flexClass">
                                    <img class="add_user_icon alignSelfCenter" src="assets/classroom/classroom_icons/add_user.png" alt="">
                                    <span class="alignSelfCenter">Unregistered users</span>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
           
            <div class="col-sm-12 col-md-8 col-lg-6 col-xl-6 noPadding text-right flexClass mob_mt" style="justify-content: end;" *ngIf="(sess_status == 3) || (sess_status == 1)">
                <div class="alignSelfCenter flexClass">
                    <div class="attendance_code_sec flexClass alignSelfCenter">
                        <div class="smallText share_text_block alignSelfCenter lh text-left"
                            *ngIf="attendance_status ==1">
                            Attendance is closed, user cant submit the attendance
                        </div>
                        <div class="smallText share_text_block alignSelfCenter lh text-left"
                            *ngIf="attendance_status ==0 &&  is_lc_session == 1 &&  session_got_canceled == 0">
                            Share this code to the users to submit attendance
                        </div>
                        <div class="smallText share_text_block alignSelfCenter lh text-left"
                            *ngIf="attendance_status ==0 && is_lc_session == 0  &&  session_got_canceled == 0">
                            Session Code: For Attendance and Walk in Learners
                        </div>
                        <div class="code_block flexClass" *ngIf="attendance_status ==0  &&  session_got_canceled == 0">
                            <span class="code alignSelfCenter subHeading icon_mr">{{session_key}}</span>
                            <img class="location_icon alignSelfCenter pointer" (click)="copyText(session_key)"
                                src="assets/classroom/classroom_icons/copy.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="btn_sec flexClass" *ngIf="!isMobile">
                    <!-- <button class="secondaryBtn red_btn alignSelfCenter" (click)="attendance_status_update(1)"
                        *ngIf="attendance_status ==0">Close attendance</button> -->
                    <button class="secondaryBtn alignSelfCenter" (click)="attendance_status_update(0)"
                        *ngIf="attendance_status ==1">Open attendance</button>
                </div>

            </div>
        </div>
        <div class="row noMargin participant_header_sec" *ngIf="userType == 'trainer'">
            <div class="col-sm-12 col-6 col-md-4 col-lg-4 col-xl-4 noPadding flexClass" *ngIf="isMarkedbutton || scoreSec"></div>
            <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8 noPadding text-right flexClass mob_mt" style="justify-content: end;">
                <div class="alignSelfCenter flexClass">
                    <div class=" flexClass alignSelfCenter">
                        <!-- <div  class ="rev_mark_mar_left" >
                            <button type="button" *ngIf="scoreSec == true" (click)="add_score(fetchUserList)" class="primaryBtn">Add Score</button> 
                        </div>  -->
                        <div *ngIf="isMarkedbutton" class = "rev_mark_mar_left"><button type="button" class = "remove_button rev_mark_button" (click)="remove_marked_user('remove_marked_user')">Remove marked user</button></div>
                        <div *ngIf="isMarkedbutton" class = "rev_mark_mar_left"><button type="button" class = "mark_button rev_mark_button" (click)="remove_marked_user('mark_as_not_attended')">Mark as not attended</button></div>
                        <div *ngIf="isMarkedbutton" class = "rev_mark_mar_left"><button type="button" class = "mark_button rev_mark_button" (click)="remove_marked_user('mark_as_attended')">Mark as attended</button></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row noMargin participant_header_sec" *ngIf="(userType == 'learner') && (attendance_status !=1)">
            <div class="submit_sec flexClass row noMargin" *ngIf="!attendanceSubmitted && session_got_canceled==0 && !isTimeExceed">
                <div class="session_input alignSelfCenter">
                    <igx-input-group class="input_border primaryText" type="box">
                        <input igxInput name="meeting_link" type="text" [(ngModel)]="sessionIdEntered" />
                        <label igxLabel for="meeting_link">Enter session id</label>
                    </igx-input-group>
                </div>
                <div class="submit_session_btn alignSelfCenter ml-2">
                    <button class="primaryBtn blue_bg" (click)="submitAttendance()">Submit</button>
                </div>
                <div class="col-12 noPadding smallText info_text">
                    Enter the code provided by session trainer to submit attendance
                </div>
            </div>
            <div class="submited_msg flexClass subHeading" *ngIf="attendanceSubmitted">
                Attendance submitted successfully!
            </div>
            <div class="flexClass subHeading red_text" *ngIf="(isTimeExceed || session_got_canceled == 1) && !attendanceSubmitted">
                Session is closed, you can't submit the attendance
            </div>

        </div>
        <div class="row noMargin participant_body_sec">
            <div class="col-sm-12 filter_att" *ngIf = "isselectedAttribute">
                <div class = "row attribute_name_row" *ngIf="!isMobile">
                    <div class="col-sm-12 col-12 col-md-12 noPadding flexClass attribute_name" >
                        <div class = "subHeading alignSelfCenter icon_mr col-sm-4 col-lg-2 col-md-2 filter_applied filter_att">Filters applied:</div>
                        <div class = "alignSelfCenter  col-sm-4 icon_mr col-lg-3 col-md-3 "><span class = "subHeading filter_att">Attribute : </span><span class="att_name filter_att"> {{attributeName}}</span></div>
                        <div class = "alignSelfCenter  col-sm-4 icon_mr col-lg-3 col-md-3 "><span class = "subHeading filter_att">Attribute Value : </span><span class="att_name filter_att"> {{attributeText}}</span></div>
                    </div>
                </div>
            </div>
            <div class="table_sec col-12 noPadding text-center flexClass"
                *ngIf="fetchUserList.length != 0 && userType == 'trainer'">
                <table class="cust_table">
                    <thead>
                        <tr>
                            <th *ngIf = "session_got_canceled ==0 && sess_status != 1"></th>
                            <th>Name</th>
                            <th>Email ID</th>
                            <!-- <th *ngIf="is_lc_session==0">Status</th> -->
                            <!-- is_lc_session == 1 -->
                            <th>UserType</th>
                            <th class="pre_score" *ngIf="is_lc_session==0" >Registration Method</th>
                            <th class="pre_score" *ngIf="is_lc_session==0" >Pre Score</th>
                            <th class="pre_score" *ngIf="is_lc_session==0" >Post Score</th>
                            <th>Attendance</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let userList of fetchUserList; let i=index;">
                            <td *ngIf = "session_got_canceled ==0 && sess_status != 1" ><input type = "checkbox" [(ngModel)]="userList.checked" (change)="handleCheckboxChange($event)" ></td>
                            <td>{{ userList.full_name }}</td>
                            <td>{{ userList.email_id }}</td>
                            <!-- <td *ngIf="is_lc_session == 0">{{userList.user_status_display}}</td> -->
                            <!-- is_lc_session == 1 && -->
                            <td *ngIf="userList.registered == 0">
                                Registered</td>
                            <td *ngIf="userList.registered == 1">
                                    Unregistered</td>
                            <td *ngIf="userList.register_method == 0 && is_lc_session==0">
                                By Trainer</td>
                            <td *ngIf="userList.register_method == 1 && is_lc_session==0">
                                Session code</td>
                            <td class="pre_score" *ngIf="is_lc_session==0">
                                <input class="score_text" type="number"   [(ngModel)]="userList.pre_score" [disabled]="((userList.is_attended == 0) && (sess_status == 0 || sess_status == 1 || sess_status == 2 || sess_status == 3 ||sess_status == 4)) || ((userList.is_attended == 1) && (sess_status == 0  || sess_status == 2  || sess_status == 4)) || elevenfiftyNinePm == true">
                            </td>
                            <td class="pre_score" *ngIf="is_lc_session==0">
                                <input  class="score_text" type="number"  [(ngModel)]="userList.post_score" [disabled]="((userList.is_attended == 0) && (sess_status == 0 || sess_status == 1 || sess_status == 2 || sess_status == 3 || sess_status == 4)) || ((userList.is_attended == 1) && (sess_status == 0  || sess_status == 2 || sess_status == 4)) || elevenfiftyNinePm == true">
                            </td>
                            <!-- <td class="pre_score" *ngIf="is_lc_session==0">
                                <button [disabled]="userList.is_attended == 0" (click)="add_score(userList)" class="primaryBtn">Add</button> 
                            </td> -->
                            <td *ngIf="(sess_status == 3  &&  elevenfiftyNinePm == false && is_lc_session == 0) || (is_lc_session == 1 && session_got_canceled == 0 && sess_status == 3) || (sess_status == 1  &&  elevenfiftyNinePm == false && is_lc_session == 0)">
                                <select class="attendance_drop" [(ngModel)]="userList.is_attended"
                                    (change)="onSelectionChange($event,userList)"
                                    [disabled]="attendance_status == 1 ? 'true' : 'false'">
                                    <option value="1">Attended</option>
                                    <option value="0">Not Attended</option>
                                </select>
                            </td>
                            <!-- <td *ngIf="(sess_status != 3  || elevenfiftyNinePm == true) && is_lc_session == 0">
                               <span *ngIf="userList.is_attended == 0">Not Attended</span>
                               <span *ngIf="userList.is_attended == 1">Attended</span>
                            </td> -->
                            <td *ngIf="((sess_status != 3 || session_got_canceled == 1)  && is_lc_session == 1) || ((elevenfiftyNinePm == true  || sess_status == 0 || sess_status == 2)  && is_lc_session == 0)">
                                <span *ngIf="userList.is_attended == 0">Not Attended</span>
                                <span *ngIf="userList.is_attended == 1">Attended</span>
                             </td>
                        </tr>

                    </tbody>
                </table>

            </div>

            <div class="default_img_sec col-12 noPadding text-center flexClass"
                *ngIf="fetchUserList.length == 0 && userType == 'trainer'">
                <div class="inner_class alignSelfCenter mx-auto">
                    <img class="default_img_attendence" src="assets/classroom/images/attendance_default.png" alt="">
                    <div class="default_text subHeading mt-2">
                        There have been no participants joined yet. Open the attendance and share the code to let the
                        user submit attendance
                    </div>
                </div>

            </div>
        </div>
    </div>

    <ng-template #userModel let-modal>
        <div *ngIf = "session_type == 1">
        <!-- Modal body -->
        <div class="modal-body row noMargin noPadding">
            <div class="col-12 modal_body">
                <div class="row noMargin primaryText mb-1">Who are the learners?</div>
                <div class="row noMargin primaryText mb-1">
                    <angular2-multiselect [data]="userList" [(ngModel)]="selectedUsers"
                        [settings]="userDropdownSettings"></angular2-multiselect>
                </div>
                <div class="row noMargin primaryText normal_mt">Selected users</div>
                <div class="row noMargin primaryText selected_user_sec">
                    <div class="col-12 noPadding empty_state flexClass mx-auto" *ngIf="selectedUsers.length == 0">
                        <div class="row noMargin primaryText default_msg alignSelfCenter">
                            No users have been selected yet
                        </div>
                    </div>
                    <div class="col-12 noPadding" *ngIf="selectedUsers.length != 0">
                        <div class="row noMargin">
                            <div class="col-12 noPadding user_list flexClass mx-auto"
                                *ngFor="let userList of selectedUsers; let i=index;">
                                <div class="row noMargin each_user_row alignSelfCenter">
                                    <div class="col-6 noPadding alignSelfCenter">
                                        <span class="primaryText alignSelfCenter">{{userList.full_name}}</span>
                                    </div>
                                    <div class="col-6 noPadding alignSelfCenter text-right">
                                        <div class="row noMargin" style="justify-content: end;">
                                            <span class="smallText alignSelfCenter mr-2">{{userList.email_id}}</span>
                                            <!-- <img class="cancel_img alignSelfCenter"
                                                src="assets/classroom/classroom_icons/cancel.png" alt=""> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-12 modal_footer text-right">
                <button (click)="add_new_users()" class="primaryBtn">Save</button>
            </div>
        </div>
        </div>
        <div *ngIf = "session_type == 0">
            <!-- Modal body -->
            <div class="modal-body row noMargin noPadding">
                <div class="col-12 modal_body">
                    <div class="row noMargin primaryText mb-1 attribut_margin_one">Select user group by attributes</div>
                    <div class="row noMargin primaryText mb-2 attribut_margin_one">
                        <angular2-multiselect [data]="attribute" [(ngModel)]="selectedAttribute"
                            [settings]="attributeDropdownSettings" (onSelect)="onSelectedChange($event)"></angular2-multiselect>
                    </div>
                <div class="row noMargin primaryText mb-2 attribut_margin" *ngIf = "isAttributeSelect && isAttributeMultiSelect">
                        <angular2-multiselect [data]="attributeOptions" [(ngModel)]="selectedAttributeOption"
                            [settings]="attributeOptionDropdownSettings"></angular2-multiselect>
                    </div>
                    <div class="row noMargin primaryText mb-2 attribut_margin" *ngIf = "isAttributeSelect && isAttributeNoOption == true">
                        <input type = "text" [(ngModel)]="selectedAttributeText" class="primaryText form-control" placeholder="Enter here">
                    </div>
                  
                </div>
                <div class="col-12 modal_footer text-right">
                    <button (click)="get_users()" class="primaryBtn">Save</button>
                </div>
            </div>
        </div>

    </ng-template>

    <ng-template #closeSession let-modal>
        <div class="modal-body row noMargin noPadding">
            <div class="col-12 modal_body">
                <div class="row noMargin primaryText mb-1" style="justify-content: center;">
                    The session cannot be re-opened once it is closed. Do you want to go ahead and close the session?
                </div>
                <div class="row noMargin primaryText mb-1" style="justify-content: center;">
                    <button (click)="closeModal()" class="secondaryBtn mr-2 grey_btn">Cancel</button>
                    <button (click)="update_session_status(1, fetchUserList, 'close')" class="secondaryBtn mr-2 red_btn">Close</button>
                </div>
            </div>
        </div>
    </ng-template>
</div>