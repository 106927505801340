<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1,user-scalable=0"/>
<div class="card main_card">
   <div class="col-md-12 header_main_div noPadding">
      <h4 class="panel-title">
         <span class="panelTitle noPadding">
         <i class="fa fa-bar-chart-o"></i>&nbsp;Task Type
         </span>
      </h4>
   </div>
  <span class="span_msg" *ngIf="userRole == 3">You are not authorised to access this page.</span>
   <div class="col-md-12 filter_main_div" *ngIf="userRole != 3">
      <div class="col-md-12 text-right noPadding">
         <div class="dropdown" style="margin-top: 20px;">
            <button class="btn btn-teel-blue download_btn" type="button" (click)="AddTypeOpen(typeContent)">
            Add Task Type
            </button>
         </div>
         <div class="marginTop">
            <table class="table table-striped">
               <thead>
                  <tr>
                     <th scope="col">ID</th>
                     <th scope="col">Task Type</th>
                     <th scope="col">Action</th>
                  </tr>
               </thead>
               <tbody>
                  <tr *ngFor="let allTaskTypeData of allTaskTypeList; let i=index;">
                     <th scope="row">{{allTaskTypeData.id}}</th>
                     <td>{{allTaskTypeData.task_type}}</td>
                     <td>
                       <i class="fa fa-edit edit_icon" (click)="editOpen(editContent,allTaskTypeData.id)"> </i>
                       &nbsp; <img class="delete_type" src="assets/task_manager_icons/delete.svg" (click)="deleteOpen(deleteContent,allTaskTypeData.id)"/>
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>
      </div>
      <ng-template #deleteContent let-modal>
         <div class="modal-header">
            <h4 class="modal-title delete_title" id="modal-basic-title" >Delete Task Type</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span>&times;</span>
            </button>
         </div>
         <div class="modal-body">
            <div class="row" style="padding:15px;">
               <div class="col-md-12 col-sm-12 col-xs-12 text-center" style="margin-bottom: 15px;">
                  <h4 class="delete_head" style="margin-top: 0px !important;">Are you sure you want to continue?</h4>
                  <p class="debug-url"></p>
               </div>
               <div class="col-md-6 col-md-offset-6">
                  <button type="button" class="btn btn-teel-green delete_btn" (click)="deleteTaskType()">Yes</button>
               </div>
               <div class="col-md-6">
                  <button type="button" class="btn red full-btn-width cancel_btn" style="border-radius: 4px !important;" (click)="modal.close('Save click')">No</button>
               </div>
            </div>
         </div>
      </ng-template>


     <ng-template #typeContent let-modal>
         <div class="modal-header">
            <h4 class="modal-title delete_title" id="modal-basic-titles" >Task Type</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span>&times;</span>
            </button>
         </div>
         <div class="modal-body">
            <div class="row" style="padding:15px;">
               <div class="col-md-12 col-sm-12 col-xs-12 text-center" style="margin-bottom: 15px;">
                  <h4 class="delete_head" style="margin-top: 0px !important;">
                    <input type="text" class="form-control-lg border-0 flex-grow-1 task_input"  name="task_type"  [(ngModel)]="task_type"
                                      placeholder="Enter New Task Type" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Add New Task Type'" />

                  </h4>
                  <p class="debug-url"></p>
               </div>
               <div class="col-md-6 col-md-offset-6">
                  <button type="button" class="btn btn-teel-green delete_btn" (click)="saveTaskType()">Save</button>
               </div>
               <div class="col-md-6">
                  <button type="button" class="btn red full-btn-width cancel_btn" style="border-radius: 4px !important;" (click)="modal.close('Save click')">Remove</button>
               </div>
            </div>
         </div>
      </ng-template>

     <ng-template #editContent let-modal>
         <div class="modal-header">
            <h4 class="modal-title delete_title" id="modal-basic-title_edit" >Edit Task Type</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span>&times;</span>
            </button>
         </div>
         <div class="modal-body">
            <div class="row" style="padding:15px;">
               <div class="col-md-12 col-sm-12 col-xs-12 text-center" style="margin-bottom: 15px;">
                  <h4 class="delete_head" style="margin-top: 0px !important;"> <input type="text" class="form-control-lg border-0 flex-grow-1 task_input"  name="task_type_added"  [(ngModel)]="task_type_added"
                                      placeholder="Enter New Task Type" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Add New Task Type'" />
</h4>
                  <p class="debug-url"></p>
               </div>
               <div class="col-md-6 col-md-offset-6">
                  <button type="button" class="btn btn-teel-green delete_btn" (click)="updateTaskType()">Update</button>
               </div>
               <div class="col-md-6">
                  <button type="button" class="btn red full-btn-width cancel_btn" style="border-radius: 4px !important;" (click)="modal.close('Save click')">Cancel</button>
               </div>
            </div>
         </div>
      </ng-template>


   </div>
</div>
