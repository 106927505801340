import {
	analyzeAndValidateNgModules
} from '@angular/compiler';
import {
	Component,
	OnInit,
	Output,
	EventEmitter,
	ViewChild, ChangeDetectionStrategy,ChangeDetectorRef
} from '@angular/core';
import {
	TaskManagementService
} from 'src/app/services/task-management.service';
import {
	ExcelServicesService
} from 'src/app/services/excel-services.service';
import {
	NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import {
	FormGroup,
	FormControl
} from '@angular/forms';
import {
	DatePipe
} from '@angular/common';
import {
	MatDialogModule
} from '@angular/material/dialog';
import {
	GlobalConstants
} from 'src/app/global-const';
import {
	NgbModal,
	ModalDismissReasons
} from '@ng-bootstrap/ng-bootstrap';

import * as alertify from 'alertifyjs';
import {
	IgxExpansionPanelComponent
} from "igniteui-angular";
import {
	NgxSpinnerService
} from 'ngx-spinner';
import * as XLSX from 'xlsx';

import { Utils } from 'src/app/config/utils';

import { MatSidenav } from '@angular/material/sidenav';
// AOA : array of array
type AOA = any[][];
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-task-type',
  templateUrl: './task-type.component.html',
  styleUrls: ['./task-type.component.css','../task-reminder/task-reminder.component.scss', '../task-create/task-create.component.css', '../task-view/task-view.component.css','../task-report/task-report.component.css','../task-list/task-list.component.css']
})
export class TaskTypeComponent implements OnInit {

  constructor(private modalService: NgbModal,private taskService: TaskManagementService,
  private cdRef:ChangeDetectorRef) { }

  ngOnInit(): void {
   this.getTaskTypes();
   this.getLoggedInUserRole();
  }

 allTaskTypeList: any;
 getTaskTypes() {
		const self = this;
		let allTaskListPromise = new Promise((resolve, reject) => {
			this.taskService.getTaskTypes().toPromise().then(res => {
			this.allTaskTypeList = res;
				//this.cdRef.markForCheck();
			})
		})
	}


 AddTypeOpen(content) {
		this.modalService.open(content, {
			ariaLabelledBy: 'modal-basic-title'
		}).result.then((result) => {}, (reason) => {});
	}
	task_type:any;

	saveTaskType() {
		if (this.task_type == '' || this.task_type == undefined || this.task_type == null) {
			alertify.error('Enter Task Type');
		} else {
			const data = {
				"task_type": this.task_type,
				"status": 0,
			};

			let promise = new Promise((resolve, reject) => {
			this.taskService.saveTaskType(data).toPromise().then(res => {
			  this.modalService.dismissAll();
        alertify.success('Task Type Created Successfully!');
        this.getTaskTypes();

			})
		})
		}
	}

confirmDeleteId: any;
 deleteOpen(content, id) {
		this.confirmDeleteId = id
		this.modalService.open(content, {
			ariaLabelledBy: 'modal-basic-title'
		}).result.then((result) => {}, (reason) => {});
	}

	deleteTaskType(){
     const data = {
			"id": this.confirmDeleteId,
			"status": 1
		};
		console.log(data);
		let promise = new Promise((resolve, reject) => {
			this.taskService.updateTaskType(data).toPromise().then(res => {
				this.modalService.dismissAll();
				alertify.success('Task Type Deleted Successfully!');
        this.getTaskTypes();
			})
		})
	}
  task_type_added:any;
	confirmEditId: any;
 editOpen(content, id) {
		this.confirmEditId = id

		const self = this;
		let promise = new Promise((resolve, reject) => {
			this.taskService.getEditTaskType(this.confirmEditId).toPromise().then(res => {
			this.task_type_added = res[0].task_type;
			console.log(res)
				this.cdRef.markForCheck();
			})
		})


		this.modalService.open(content, {
			ariaLabelledBy: 'modal-basic-title'
		}).result.then((result) => {}, (reason) => {});
	}


	updateTaskType(){
	console.log(this.task_type_added)

	 const data = {
			"id": this.confirmEditId,
			"task_type": this.task_type_added
		};
	  const self = this;
		let promise = new Promise((resolve, reject) => {
			this.taskService.updateTaskType(data).toPromise().then(res => {
			this.modalService.dismissAll();
				alertify.success('Task Type updated Successfully!');
        this.getTaskTypes();
			})
		})
	}

  userRole:any;
  getLoggedInUserRole() {
		this.taskService.getLoggedInUserRole().subscribe(res => {
			this.userRole = res;
		})
	}


}
