import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { InfogeonClassroomService } from '../infogeon-classroom.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-learn-login',
  templateUrl: './learn-login.component.html',
  styleUrls: ['./learn-login.component.scss']
})
export class LearnLoginComponent implements OnInit {

  constructor(
    private classroomService: InfogeonClassroomService,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private httpClient: HttpClient, private router: Router,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    var that = this;
    this.activatedRoute.paramMap.subscribe((params) => {
      const user_data = params.get('user_data');
      const token = params.get('token');

       console.log(user_data);
       console.log(token);
      
        this.createLearnLogin(user_data,token);
      
    });
  }

  createLearnLogin(user_data: any, token: any): void {
    this.classroomService.createLearnLogin(user_data, token).subscribe(
      (res: any) => {
        console.log(res);

        // Check if res.base_url is not empty
        // if (res.base_url && res.base_url.trim() !== '') {

          // Continue with the rest of your code
          this.setCookies(res);

          // this.setCookies(res);

          //       // Storing data in localStorage
          // localStorage.setItem('SessionKey', 'your-session-key');

          // // Retrieving data from localStorage
          // const sessionKey = localStorage.getItem('SessionKey');

        // }

        },
      (error: any) => {
        console.error(error);
      }
    );
  }

  setCookies(response: any): void {
    
    // Set cookies in Angular using ngx-cookie-service
    // for prod--
    this.cookieService.set('sessionid', response.SessionKey, 5, '/', 'bsharpcorp.com');
    this.cookieService.set('csrf_token', response.csrf_token, 5, '/', 'bsharpcorp.com');
    this.cookieService.set('base_url', response.base_url, 5, '/', 'bsharpcorp.com');

    //for qa--
    // this.cookieService.set('sessionid', response.SessionKey, 5, '/', 'bsharp.in');
    // this.cookieService.set('csrf_token', response.csrf_token, 5, '/', 'bsharp.in');
    // this.cookieService.set('base_url', response.base_url, 5, '/', 'bsharp.in');

    //for local--
    // this.cookieService.set('sessionid', response.SessionKey, 5, '/', 'localhost');
    // this.cookieService.set('csrf_token', response.csrf_token, 5, '/', 'localhost');
    // this.cookieService.set('base_url', response.base_url, 5, '/', 'localhost');

    this.router.navigate([response.destination]);
  }



}
