<div class="card error_card" *ngIf="error_excel">
<!-- <div class="card error_card"> -->
  <div class="card-body">
    <h5 class="card-title standard_black">Errors in Upload</h5>
    <p class="card-text standard_black">Errors occured in upload. Rows with error have not been upload. Correct the errors and upload.</p>
    <button  (click)="download_error_excel()" class="btn btn-teel-blue search-btn">Download errors</button>
  </div>
</div>
<div class="card main_card">
    <div class="col-md-12 header_main_div noPadding">
        <div class="col-md-10 noPadding displayInline">
            <h4 class="panel-title">
            <span class="panelTitle noPadding">
                <i class="fa fa-bar-chart-o"></i>&nbsp;Add multiple task
            </span>
            </h4>
        </div>
        <div class="col-md-2 back-btn noPadding displayInline">
            <a href="/taskmanagement/admin" class="page-quick-sidebar-back-to-list">
            <i class="fa fa-arrow-circle-o-left"></i> Back</a>
        </div>
    </div>
  <span class="span_msg text-center" *ngIf="userRole == 3">You are not authorised to access this page.</span>
    <div class="panel" *ngIf="userRole != 3">
        <div class="panel-body paddingBottom0 paddingTop10">
            <div class="text-center download_temp_sec">
                <img src="assets/task_manager_icons/excelFile.png" class="img-excel img-responsive" alt="">
                <p class="form_labelTittle marginTop15">
                    <button type="button" class="btn btn-next" (click)="downloadTemplate()">Download template</button>
                </p>
            </div>
            <div class="noMargin">
                <div class="text-center">
                    <p class="form_labelTittle" style="margin-top: 0 !important">Upload excel file</p>
                    <div class="fileinputBtn-overide">
                        <div class="uploadForm" method="post" enctype="multipart/form-data">
                            <input type="file" accept=".xlsx" multiple="false" name="upload_sheet" ngModel (change)="inputExcelOnClick($event)">
                        </div>
                    </div>
                </div>
            </div>
            <div class="marginBottom15">
                <div class="col-md-12 col-sm-12 col-xs-12 text-right submit_btn_div">
                    <button class="btn btn-teel-blue search-btn" (click)="submitUploadSheetData()">Submit</button>
                </div>
            </div>
        </div>

    </div>

</div>

