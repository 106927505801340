<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#139cd7" type="ball-spin-clockwise"
        [fullScreen]="false">
        <p style="color: white"> Processing........ </p>
</ngx-spinner>
<div class="row noMargin create_container">
  <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8 noPadding flexClass mx-auto">
    <a href="/classroom/view_session/{{session_id}}" style="color:#000000">
      <img class="back_arrow alignSelfCenter" src="/assets/task_manager_icons/task_manager_back_arrow.svg" alt="">
    </a>
    <span class="mainHeading ml-2 alignSelfCenter">Create Session</span>
  </div>

  <div class="col-12 col-sm-11 col-md-10 col-lg-9 col-xl-8 noPadding mx-auto">
    <div class="row noMargin">
      <div class="col-12 add_part white_sec normal_mt">
        <div class="row noMargin body_sec">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 part_head noPadding">
            <div class="row noMargin ">
              <div class="col-6 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 alignSelfCenter">
                <span class="mainHeading alignSelfCenter">Add Participants</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 part_head noPadding">
              <form [formGroup]="formGroup" (ngSubmit)="filterUser()">
                  <div class="row noMargin alignSelfCenter">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 attr_list">
                    
                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <div class="col-12 input_head primaryText">Attribute 1</div>
                            <div class="col-12 input_body noPadding">
                                <div class="form-group noMargin">
                                    <angular2-multiselect [data]="attribute_list" [settings]="attributeDropdownSettings"
                                       formControlName="aid_one" (ngModelChange)="onAttributeSelect($event)"></angular2-multiselect>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <div class="col-12 input_head primaryText">Attribute Option</div>
                            <div class="col-12 input_body noPadding">
                                <div class="form-group noMargin">
                                    <angular2-multiselect [data]="option_one" [settings]="attributeOptionDropdownSettings"
                                         formControlName="opt_one"></angular2-multiselect>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 attr_list">
                    
                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <div class="col-12 input_head primaryText">Attribute 2</div>
                            <div class="col-12 input_body noPadding">
                                <div class="form-group noMargin">
                                    <angular2-multiselect [data]="attribute_list" [settings]="attributeDropdownSettings"
                                       formControlName="aid_two"
                                        (ngModelChange)="secondAttributeSelect($event)"></angular2-multiselect>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <div class="col-12 input_head primaryText">Attribute Option</div>
                            <div class="col-12 input_body noPadding">
                                <div class="form-group noMargin">
                                    <angular2-multiselect [data]="option_two" formControlName="opt_two" [settings]="attributeOptionDropdownSettings"      
                                        ></angular2-multiselect>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div  class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 attr_list">
                    
                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <div class="col-12 input_head primaryText">Attribute 3</div>
                          <div class="col-12 input_body noPadding">
                              <div class="form-group noMargin">
                                  <angular2-multiselect [data]="attribute_list" [settings]="attributeDropdownSettings"
                                     formControlName="aid_three"
                                      (ngModelChange)="thirdAttributeSelect($event)"></angular2-multiselect>
                              </div>
                          </div>
                      </div>
                      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <div class="col-12 input_head primaryText">Attribute Option</div>
                          <div class="col-12 input_body noPadding">
                              <div class="form-group noMargin">
                                  <angular2-multiselect [data]="option_three" formControlName="opt_three" [settings]="attributeOptionDropdownSettings"      
                                      ></angular2-multiselect>
                              </div>
                          </div>
                      </div>
                    </div>

                    <div  class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 attr_list">
                    
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <div class="col-12 input_head primaryText">Attribute 4</div>
                        <div class="col-12 input_body noPadding">
                            <div class="form-group noMargin">
                                <angular2-multiselect [data]="attribute_list" [settings]="attributeDropdownSettings"
                                   formControlName="aid_four"
                                    (ngModelChange)="fourAttributeSelect($event)"></angular2-multiselect>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <div class="col-12 input_head primaryText">Attribute Option</div>
                        <div class="col-12 input_body noPadding">
                            <div class="form-group noMargin">
                                <angular2-multiselect [data]="option_four" formControlName="opt_four" [settings]="attributeOptionDropdownSettings"      
                                    ></angular2-multiselect>
                            </div>
                        </div>
                    </div>
                    </div>

                    <div  class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 attr_list">
                          
                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <div class="col-12 input_head primaryText">Attribute 5</div>
                            <div class="col-12 input_body noPadding">
                                <div class="form-group noMargin">
                                    <angular2-multiselect [data]="attribute_list" [settings]="attributeDropdownSettings"
                                      formControlName="aid_five"
                                        (ngModelChange)="fiveAttributeSelect($event)"></angular2-multiselect>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <div class="col-12 input_head primaryText">Attribute Option</div>
                            <div class="col-12 input_body noPadding">
                                <div class="form-group noMargin">
                                    <angular2-multiselect [data]="option_five" formControlName="opt_five" [settings]="attributeOptionDropdownSettings"      
                                        ></angular2-multiselect>
                                </div>
                            </div>
                        </div>
                    </div>               
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 attr_btn text-right">
                      <div class="col-2 col-sm-1 col-md-1 col-lg-1 col-xl-1">
                          <button *ngIf="isFilterApplied" type="button" (click)="resetBtnClick()"
                              class="resetBtn mr-2 alignSelfCenter primaryText">Reset</button>
                      </div>
                      <div class="col-1 col-sm-1 col-md-2 col-lg-2 col-xl-2">
                          <!-- <div class="row form-group noMargin half_pr small_device_margin mob_width"> -->
                          <button type="submit" class="secondaryBtn mr-2 blue_bg alignSelfCenter">Apply</button>
                          <!-- </div> -->
                      </div>
                  </div>
              </form>
          
              <!-- unselected users -->
              <div class="table_sec col-12 col-sm-11 col-md-10 col-lg-10 col-xl-11 mx-auto noPadding">
                <!-- <h2 class="col-12 sel" *ngIf="user_list.length != 0">Unselected users</h2> -->
                <div class="table-wrapper" *ngIf="user_list.length != 0">
                  <table class="table">
                    <thead class="cell div_flex">
                      <tr class="table_tr">
                        <th class="table_th">
                          <input type="checkbox" [(ngModel)]="selectAll" (change)="SelectAllClick($event)">
                        </th>
                        <th class="table_th primaryText">Name</th>
                        <th class="table_th primaryText">Email</th>
                        <!-- <th class="table_th primaryText">Designation</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="table_tr" *ngFor="let item of user_list; let i = index">
                        <td class="text-center table_td">
                          <input [(ngModel)]="item.selected" type="checkbox" id="coursesCheckbox_{{i}}"
                            (change)="collectModule(item,$event)">
                        </td>
                        <td class="cell date_flex">
                          <div class="table_td">{{item.full_name}}</div>
                        </td>
                        <td class="cell sml_flex primaryText">
                          <div class="table_td">{{item.email_id}}</div>
                        </td>
                        <!-- <td class="cell sml_flex primaryText">
                          <div class="table_td">{{item.designation}}</div>
                        </td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="default_img_sec col-12 noPadding text-center flexClass" *ngIf="user_list.length == 0">
                  <div class="inner_class alignSelfCenter mx-auto">
                    <img class="default_img_attendence" src="assets/classroom/images/user_session_default.png" alt="">
                    <div class="default_text subHeading mt-2">
                      There are no selected users
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        
        <div class="row noMargin footer_sec">
          <button (click)="AddRtmParticipants()" type="submit" class="primaryBtn">Create Session</button>
        </div>
      </div>
    </div>
  </div>
</div>
