import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { IgxRadioModule, IgxTreeGridModule } from "igniteui-angular";
import { FormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { IgxInputGroupModule } from 'igniteui-angular';
import { HammerModule } from '@angular/platform-browser';
import { IgxDatePickerModule } from 'igniteui-angular';
import { IgxTimePickerModule, IgxIconModule, IgxSnackbarModule } from "igniteui-angular";
import { ReactiveFormsModule } from '@angular/forms';
import { IgxSelectModule } from 'igniteui-angular';
import { NgCircleProgressModule } from 'ng-circle-progress';


import { InfogeonClassroomRoutingModule } from './infogeon-classroom-routing.module';
import { CreateSessionComponent, NgbDateCustomParserFormatter } from './create-session/create-session.component';
import { SessionListComponent } from './session-list/session-list.component';
import { IndividualSessionComponent } from './individual-session/individual-session.component';
import { DateChipComponent } from './date-chip/date-chip.component';
import { UserSessionListComponent } from './user-session-list/user-session-list.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LearnLoginComponent } from './learn-login/learn-login.component';
import { RtmAddParticipantComponent } from './rtm-add-participant/rtm-add-participant.component';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  declarations: [
    CreateSessionComponent,
    SessionListComponent,
    IndividualSessionComponent,
    DateChipComponent,
    UserSessionListComponent,
    LearnLoginComponent,
    RtmAddParticipantComponent,
  ],
  imports: [
    CommonModule,
    InfogeonClassroomRoutingModule,
    IgxRadioModule,
    FormsModule,
    AngularMultiSelectModule,
    IgxInputGroupModule,
    HammerModule,
    IgxDatePickerModule,
    IgxTimePickerModule,
    IgxIconModule,
    ReactiveFormsModule,
    IgxSelectModule,
    IgxSnackbarModule,
    IgxTreeGridModule,
    NgCircleProgressModule.forRoot(),
    NgxPaginationModule,
    NgbModule,
    NgxSpinnerModule

  ],
  exports: [DateChipComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [DatePipe,{provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}],
})
export class InfogeonClassroomModule { }
