import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { InfogeonClassroomService } from '../infogeon-classroom.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbCalendar, NgbDateAdapter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { InfogeonLearningChampionService } from '../../infogeon-learning-champion/infogeon-learning-champion.service';
import * as alertify from 'alertifyjs';
@Component({
  selector: 'app-rtm-add-participant',
  templateUrl: './rtm-add-participant.component.html',
  styleUrls: ['./rtm-add-participant.component.scss']
})
export class RtmAddParticipantComponent implements OnInit {
  session_id: any;
  selectedAttrOne:any = [];
  selectedAttrTwo:any = [];
  selected_opt_one:any = [];
  selected_opt_two:any = [];
  option_one:any = [];
  option_two:any = [];
  attribute_list = [];
  selectedAttrFive: any = [];
  option_five: any = [];
  selectedAttrFour: any = [];
  option_four: any = [];
  selectedAttrThree: any = [];
  option_three: any = [];
  attributeDropdownSettings = {
    singleSelection: true,
    text: "Select Attribute",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    showCheckbox: true,
    labelKey: 'attribute_name',
    maxHeight: 120,
    // lazyLoading: true,
    primaryKey: 'aid',
    searchBy: ["aid", "attribute_name"],
    classes: "select_user_drop primaryText select_user_attri"
  };
  attributeOptionDropdownSettings = {
    singleSelection: false,
    text: "Select Attribute Option",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    showCheckbox: true,
    labelKey: 'attribute_option',
    maxHeight: 120,
    // lazyLoading: true,
    primaryKey: 'aoid',
    searchBy: ["aoid", "attribute_option"],
    classes: "select_user_drop primaryText select_user_attri select_user_attri_option"
  };
selectAll:any;
user_list:any = [];
formGroup: FormGroup;
attrObj ={};
isFilterApplied = false;
selected_users: any = [];
  constructor(
    private formBuilder: FormBuilder,
    private classroomService: InfogeonClassroomService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>,
    private titleService: Title,
    private infogeonLcService: InfogeonLearningChampionService
  ) {
    this.titleService.setTitle("Classroom");
     this.route.params.subscribe(paramsId => {
      // console.log(paramsId);
      this.session_id = paramsId.id;
      // console.log(this.session_id)
    });
    this.attrObj = {
      'selectedAttribute': [],
      'selectedAttributeOption':[],
      'selectedAttribute_2': [],
      'selectedAttributeOption_2': [],
      'selectedAttribute_3': [],
      'selectedAttributeOption_3': [],
      'selectedAttribute_4': [],
      'selectedAttributeOption_4': [],
      'selectedAttribute_5': [],
      'selectedAttributeOption_5': [],
      'sid': this.session_id,
    }
   }

  ngOnInit(): void {
    this.getAttributes();
    this.formGroup = this.formBuilder.group({
      aid_one: [],
      opt_one: [],
      aid_two: [],
      opt_two: [],
      aid_three : [],
      opt_three: [],
      aid_four: [],
      opt_four: [],
      aid_five: [],
      opt_five: [],
    });
   
    // this.fetchRtmUserList();
  }

  //get all attribute
  getAttributes() {
    
    this.classroomService.attribute_list().subscribe(attributeList => {
      this.attribute_list = attributeList;
      // console.log(this.attribute_list);
    })
  }
  onAttributeSelect(value){
    // console.log(value)
    // console.log(this.selectedAttrOne)
    this.selectedAttrOne = value[0].attribute_option;
    if(this.selectedAttrOne.length >0){
      this.option_one =  this.selectedAttrOne
    }
  }
  secondAttributeSelect(value){
    // console.log(value)
    this.selectedAttrTwo = value[0].attribute_option;
    if(this.selectedAttrTwo.length >0){
      this.option_two = this.selectedAttrTwo
    }
  }

  thirdAttributeSelect(value){
    // console.log("three",value)
    this.selectedAttrThree = value[0].attribute_option;
    if(this.selectedAttrThree.length >0){
      this.option_three = this.selectedAttrThree
    }
  }
  fourAttributeSelect(value){
    // console.log("four",value)
    this.selectedAttrFour = value[0].attribute_option;
    if(this.selectedAttrFour.length >0){
      this.option_four = this.selectedAttrFour
    }
  }
  fiveAttributeSelect(value){
    // console.log("five",value)
    this.selectedAttrFive = value[0].attribute_option;
    if(this.selectedAttrFive.length >0){
      this.option_five = this.selectedAttrFive
    }
  }
 
  resetBtnClick(){
    this.formGroup.reset();
    this.isFilterApplied = false;
    this.selectedAttrOne = [];
    this.selectedAttrTwo = [];
    this.selectedAttrThree = [];
    this.selectedAttrFour = [];
    this.selectedAttrFive = [];
    this.user_list = [];
    
  }
  
  filterUser(){
    if(this.formGroup.value.aid_one == null && this.formGroup.value.aid_two == null && this.formGroup.value.aid_three== null && this.formGroup.value.aid_four == null && this.formGroup.value.aid_five == null){
      alertify.error("Select atleast one attribute");
      this.isFilterApplied = false;
    }else{
      this.isFilterApplied = true;
      this.spinner.show();
      this.attrObj['selectedAttribute'] = this.formGroup.value.aid_one;
      this.attrObj['selectedAttributeOption'] = this.formGroup.value.opt_one;
      this.attrObj['selectedAttribute_2'] = this.formGroup.value.aid_two;
      this.attrObj['selectedAttributeOption_2'] = this.formGroup.value.opt_two;
      this.attrObj['selectedAttribute_3'] = this.formGroup.value.aid_three;
      this.attrObj['selectedAttributeOption_3'] = this.formGroup.value.opt_three;
      this.attrObj['selectedAttribute_4'] = this.formGroup.value.aid_four;
      this.attrObj['selectedAttributeOption_4'] = this.formGroup.value.opt_four;
      this.attrObj['selectedAttribute_5'] = this.formGroup.value.aid_five;
      this.attrObj['selectedAttributeOption_5'] = this.formGroup.value.opt_five;
      this.attrObj['sid'] = this.session_id;
      this.fetchRtmUserList();
    }
    // console.log(this.attrObj);
    
  }

  fetchRtmUserList(){
    this.spinner.show();
    this.classroomService.get_users_by_attribte(this.attrObj).toPromise().then(res => { 
      // console.log("res", res)
        this.user_list = res;        
        this.selected_users = res.filter(item => (item.selected));
        // console.log(this.selected_users)
        this.spinner.hide();
    });
  }

  collectModule(item:any, event:any){
    if(event.target.checked == true){
      // this.user_list.forEach((i: any) => {
      //   if (i.uid === item.uid) {
      //     i.selected = true;
      //   } 
        const results_exp = this.user_list.filter((i: any) => i.uid=== item.uid);
        // console.log(results_exp)
        if (results_exp.length !== 0) {
          const userExists = this.selected_users.some((i: any) => i.uid === results_exp[0].uid);
          if(userExists){
          }else{
            results_exp[0].selected = true;
            this.selected_users.push(results_exp[0]);
            if (this.selected_users.length == this.user_list.length) {
              this.selectAll = true;
            }
          }      
        } 
      }else{
        const userIndex = this.selected_users.findIndex((i: any) => i.uid === item.uid);
        if (userIndex !== -1) {
            // If the user is found, remove them from the selected_users list
            item.selected = false;
            if (this.selected_users.length !== this.user_list.length) {
                this.selectAll = false;
            }
        }
      }
    console.log("selected", this.selected_users)
  }

  SelectAllClick(event:any){
    event.target.checked == true
    if(this.selectAll == true){
      this.selected_users = []
      this.user_list.forEach((item:any) => {
        item.selected = true;     
      });
      this.selected_users = this.user_list;
    }else if(this.selectAll == false){
      this.user_list.forEach((item:any) => {
        item.selected = false;
      });
      this.selected_users = [];
    }
    // console.log(this.selected_users);

  }
  AddRtmParticipants(){
      this.spinner.show();
      // console.log(this.selected_users);
      // const selected_users = this.user_list.filter(item => (item.selected))
      const save_data = {
        "user_ids": this.selected_users,
        "session_id" : this.session_id,
        'register': 0,
        'type': 0
      };
      // console.log(save_data)
      let compPromise = new Promise((resolve, reject) => {
        this.infogeonLcService.add_lc_session_users(save_data).toPromise().then(res => {
          // console.log(res)
          this.spinner.hide();
          alertify.success('Success'); 
          this.router.navigate(['/classroom/view_session/'+this.session_id]);   
        })
      });
  }

}
