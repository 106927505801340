import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-date-chip',
  templateUrl: './date-chip.component.html',
  styleUrls: ['./date-chip.component.scss']
})
export class DateChipComponent implements OnInit, OnChanges {

  @Input() dateData;

  bgColor: any = '';
  textColor: any = '';
  borderColor: any = '';
  session_date: any = '';
  sess_month: any = '';
  sess_day: any = '';
  sess_from_time: any = '';
  sess_to_time: any = '';
  from_time: any = '';
  to_time: any = '';
  timezone: any = '';
  loggedInTimeZone:any = '';

  constructor() { }
  ngOnInit(): void {

   const currentDate = new Date();
   this.loggedInTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

   //console.log(this.loggedInTimeZone);


  }

  ngOnChanges(changes: SimpleChanges) {
    var that = this;

    for (const propName of Object.keys(changes)) {
      const change = changes[propName];
      this.dateData = change.currentValue;
      // console.log(this.dateData)
      if (this.dateData) {
        this.session_date = this.dateData.session_date
        const date = new Date(this.dateData.session_date * 1000);
        this.session_date = date.getDate();
        this.sess_month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
        this.sess_day = new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(date);

        this.from_time = this.dateData.from_time
        const sess_from_date = new Date(this.dateData.from_time * 1000);
        this.sess_from_time = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(sess_from_date);


        this.to_time = this.dateData.to_time
        const sess_to_date = new Date(this.dateData.to_time * 1000);
        this.sess_to_time = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(sess_to_date);
        this.timezone = this.dateData.timezone
        this.setColors()
      }
    }

  }




  setColors() {
    if (this.dateData.status == 0) {
      this.bgColor = '#ff9d0b1a'
      this.textColor = '#E58224'
      this.borderColor = '#ff9d0b33'
    } else if (this.dateData.status == 1) {
      this.bgColor = '#5400c01a'
      this.textColor = '#240053'
      this.borderColor = '#5400c033'
    } else if (this.dateData.status == 2) {
      this.bgColor = '#ff23231a'
      this.borderColor = '#ff232333'
      this.textColor = '#E21F1F'
    }
  }

}
