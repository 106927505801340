import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { HttpClientModule,HttpClientXsrfModule } from '@angular/common/http';
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';

import { Ng2SmartTableModule } from 'ng2-smart-table';
import { Ng2CompleterModule } from "ng2-completer";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {NgxPaginationModule} from 'ngx-pagination';
// import { CarouselModule } from 'ngx-bootstrap/carousel';

// Recently added for Slide bar
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { from } from 'rxjs';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgSelectModule } from '@ng-select/ng-select';
import { ScrollingModule } from '@angular/cdk/scrolling';
// import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
// import { NgSelectModule } from '@ng-select/ng-select';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

// import { NgOtpInputModule } from 'ng-otp-input';
// import {IvyCarouselModule} from 'angular-responsive-carousel';

// import { SlickCarouselModule } from 'ngx-slick-carousel';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ClassicEditor } from '@ckeditor/ckeditor5-build-classic';

// Import the Image plugin
import Image from '@ckeditor/ckeditor5-image';
import { InfogeonClassroomModule } from './modules/infogeon-classroom/infogeon-classroom.module';
// import { InfogeonClassroomModule } from './modules/infogeon-classroom/infogeon-classroom.module';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    NavbarComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    Ng2SmartTableModule,
    Ng2CompleterModule,
    Ng2SearchPipeModule,
    NgxPaginationModule,
    NgxSpinnerModule,
    NgSelectModule,
    // CarouselModule,
    // IvyCarouselModule,
    // NgSelectModule,
    // NgOptionHighlightModule,
    CKEditorModule,
    // ClassicEditor,
    
    // NgOtpInputModule,
    // SlickCarouselModule,
    

    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-CSRF-TOKEN'
    }),
    BrowserAnimationsModule,
    NgbModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    AngularMultiSelectModule,
    
  ],
  exports: [
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    InfogeonClassroomModule,
    ],
  providers: [CookieService,DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {
}

declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}
