<!-- Content View sidenav -->
<mat-sidenav-container class="example-container" *ngIf="isContentView == true">
    <mat-sidenav #sidenav style="width: 300px !important;">
        <div class="vertical-nav bg-white" id="sidebar">
            <div class="menuSliderClose pull-right"><i class="fa fa-arrow-left slide-left-arrow"
                    (click)="sidenav.toggle()"></i></div>
            <div class="slide-profile">
                <a class="user-profile-link" href="{{navBarLink}}/company/users/userprofile/0">
                    <img *ngIf="userData.user_avt_id == 0" alt="" class="img-circle user-slider-img"
                        src="../assets/no-image.jpg" />
                    <img *ngIf="userData.user_avt_id != 0" alt="" class="img-circle user-slider-img"
                        src="{{userData.profile_img}}" />
                    <div class="profile-username"><span class="profile-slider-username">{{userData.full_name}}</span>
                    </div>
                    <div class="profile-email"><span class="profile-slider-email">{{userData.email_id}}</span></div>
                </a>
            </div>
            <ul class="nav flex-column bg-white mb-0">
                <li class="nav-item side_nav_custom">
                    <a href="{{navBarLink}}/users_dashboard" class="nav-link text-dark"> <i
                            class="fa fa-home slider-icon"></i> Home </a>
                </li>
                <li class="nav-item side_nav_custom">
                    <a href="{{navBarLink}}/switch/data" class="nav-link text-dark"> <i
                            class="fa fa-th-large slider-icon"></i> Console View </a>
                </li>
                <li class="nav-item side_nav_custom">
                    <a href="{{navBarLink}}/infogeon_user_shared_dashboard" class="nav-link text-dark"> <i
                            class="fa fa-pie-chart slider-icon"></i> My Data </a>
                </li>
                <li class="nav-item side_nav_custom">
                    <a href="{{navBarLink}}/users_channels" class="nav-link text-dark"> <i
                            class="fa fa-square slider-icon"></i> Channels </a>
                </li>
                <li class="nav-item side_nav_custom">
                    <a href="{{navBarLink}}/users_modules/0/0/0/0/0" class="nav-link text-dark"> <i
                            class="fa fa-folder slider-icon"></i> Modules </a>
                </li>
                <li class="nav-item side_nav_custom">
                    <a href="{{navBarLink}}/company/users/userprofile/0" class="nav-link text-dark"> <i
                            class="fa fa-user slider-icon"></i> View profile </a>
                </li>
                <li class="nav-item side_nav_custom">
                    <a href="{{navBarLink}}/user_getstart/explore/{{userData.uid}}" class="nav-link text-dark"> <i
                            class="fa fa-square slider-icon"></i> Get Started </a>
                </li>
                <li class="nav-item side_nav_custom">
                    <a href="{{navBarLink}}/user-management/change/language" class="nav-link text-dark"> <i
                            class="fa fa-language slider-icon"></i> Change Language </a>
                </li>
                <li class="nav-item side_nav_custom">
                    <a href="{{navBarLink}}/users/change-password/userprofile/{{userData.uid}}"
                        class="nav-link text-dark"> <i class="fa fa-unlock-alt slider-icon"></i> Change Password </a>
                </li>
                <li class="nav-item side_nav_custom">
                    <a href="{{navBarLink}}/users/privacy-customize/{{userData.uid}}" class="nav-link text-dark"> <i
                            class="fa fa-shield slider-icon"></i> Privacy </a>
                </li>
                <li class="nav-item side_nav_custom">
                    <a href="{{navBarLink}}/user_external/logout" class="nav-link text-dark"> <i
                            class="fa fa-sign-out slider-icon"></i> Logout </a>
                </li>
            </ul>
        </div>
        <div class="slider_support_mail_div text-center" *ngIf="userData.support_email != 0">
            <span>For support write to&nbsp;</span><br />
            <span>
                <a href="https://mail.google.com/mail/u/0/?view=cm&amp;fs=1&amp;to={{userData.support_email}}&amp;su=SUBJECT&amp;body=BODY&amp;tf=1"
                    class="support_mail_a" id="support_mail_a" style="border-bottom: none;">
                    {{userData.support_email}}
                </a>
            </span>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <nav class="navbar navbar-expand-lg navbar-light bg-light content_toolbar"
            style="border-bottom: 1px solid rgba(0, 0, 0, 0.12); background-color: #efefef !important;">
            <!-- <button mat-icon-button (click)="sidenav.toggle()"><mat-icon>menu</mat-icon></button> -->
            <div style="padding-right: 1%; font-size: 24px; color: #999 !important;"><i class="fa fa-bars"
                    (click)="toggleSidenav()" aria-hidden="true"></i></div>
            <div>
                <a class="navbar-brand" href="{{navBarLink}}/users_dashboard">
                    <!-- <img *ngIf="userData.company_img == ''" src="../../../../assets/Bsharp-Logo-regulars.png" alt="Home" class="img-res" /> -->
                    <img src="{{userData.company_img}}" alt="Home" class="img-res" />
                </a>
            </div>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item active"></li>
                </ul>
                <form class="form-inline my-4 my-lg-0">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item dropdown">
                            <div class="search-box" *ngIf = "userData.cmid !=22">
                                <div class="search-btn pull-right"><i class="fa fa-search search-icon"
                                        id="btnsearch"></i></div>
                                <input name="nav-search" type="text" [(ngModel)]="searchNav"
                                    (keyup.enter)="redirectSearch()" id="txt_search_bar"
                                    class="form-control search-input search-nav" placeholder="Search" />
                            </div>
                        </li>
                    </ul>
                </form>
            </div>
        </nav>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
<!-- <app-task-management></app-task-management> -->
<!-- <router-outlet></router-outlet> -->


<!-- Console View sidenav -->
<mat-toolbar class="console-toolbar" *ngIf="isContentView == false">
    <button mat-icon-button (click)="isExpanded = !isExpanded">
        <mat-icon>menu</mat-icon>
    </button>
    <div>
        <a class="navbar-brand" href="{{navBarLink}}/users_dashboard">
            <!-- <img *ngIf="userData.company_img == ''" src="../../../../assets/Bsharp-Logo-regulars.png" alt="Home" class="img-res" /> -->
            <img src="{{userData.company_img}}" alt="Home" class="img-res" />
        </a>
    </div>
</mat-toolbar>
<mat-sidenav-container class="console-container" autosize *ngIf="isContentView == false">
    <mat-sidenav #sidenav class="console-sidenav" mode="side" opened="true" (mouseenter)="mouseenter()"
        (mouseleave)="mouseleave()">
        <mat-nav-list>
            <mat-list-item class="nav_section parent parent_class">
                <img class="console_icons" src="assets/console_view_icons/General.svg">
                <span class="section_head full-width" *ngIf="isExpanded || isShowing">General</span>
            </mat-list-item>

            <mat-list-item (click)="showSubmenu = !showSubmenu" class="parent parent_class">
                <span class="full-width" *ngIf="isExpanded || isShowing">Users</span>
                <mat-icon mat-list-icon>people</mat-icon>
                <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenu}" *ngIf="isExpanded || isShowing">
                    expand_more</mat-icon>
            </mat-list-item>
            <div class="submenu" [ngClass]="{'expanded' : showSubmenu}" *ngIf="isShowing || isExpanded">
                <a mat-list-item [href]="baseUrlVar + '/user-management/active_list'">Manage Users</a>
                <a mat-list-item [href]="baseUrlVar + '/manage/groups'">User Groups</a>
                <a mat-list-item [href]="baseUrlVar + '/user-management/approver-mapping'">Approver Mapping</a>
                <a mat-list-item [href]="baseUrlVar + '/manage/attribute'">User Attributes</a>
            </div>
            <mat-list-item (click)="showSubmenu2 = !showSubmenu2" class="parent parent_class">
                <span class="full-width" *ngIf="isExpanded || isShowing">Settings</span>
                <mat-icon mat-list-icon>miscellaneous_services</mat-icon>
                <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenu2}" *ngIf="isExpanded || isShowing">
                    expand_more</mat-icon>
            </mat-list-item>
            <div class="submenu" [ngClass]="{'expanded' : showSubmenu2}" *ngIf="isShowing || isExpanded">
                <a mat-list-item [href]="baseUrlVar + '/infogeon_homepage'">App Home</a>
                <a mat-list-item [href]="baseUrlVar + '/company_setting/upload_logo'">Company Logo</a>
                <a mat-list-item [href]="baseUrlVar + '/user/config/invite'">Configure Message</a>
                <a mat-list-item [href]="baseUrlVar + '/company_setting/configure_app'">Configure App</a>
            </div>
            <mat-list-item class="parent parent_class parent_without_drop">
                <img class="console_icons" src="assets/console_view_icons/Communicate.svg">
                <a [href]="baseUrlVar + '/aws_notifications'" *ngIf="isExpanded || isShowing">Communicate</a>
            </mat-list-item>
            <mat-list-item class="nav_section parent parent_class">
                <img class="console_icons" src="assets/console_view_icons/Learn.svg">
                <span class="section_head full-width" *ngIf="isExpanded || isShowing">Bsharp Learn</span>
            </mat-list-item>
            <mat-list-item class="parent parent_class parent_without_drop">
                <a [href]="baseUrlVar + '/dashboard'" *ngIf="isExpanded || isShowing">Dashboard</a>
                <mat-icon mat-list-icon>space_dashboard</mat-icon>
            </mat-list-item>
            <mat-list-item (click)="showSubmenuContent = !showSubmenuContent" class="parent parent_class">
                <img class="console_icons" src="assets/console_view_icons/Content.svg">
                <span class="full-width" *ngIf="isExpanded || isShowing">Content</span>
                <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenuContent}"
                    *ngIf="isExpanded || isShowing">expand_more</mat-icon>
            </mat-list-item>
            <div class="submenu" [ngClass]="{'expanded' : showSubmenuContent}" *ngIf="isShowing || isExpanded">
                <a mat-list-item [href]="baseUrlVar + '/channels'">Channels</a>
                <a mat-list-item [href]="baseUrlVar + '/modules/0/0'">Modules</a>
                <a mat-list-item [href]="baseUrlVar + '/sub_channels'">Sub Channels</a>
                <a mat-list-item [href]="baseUrlVar + '/infogeon_quiz/quiz_groups_all'">Quiz Bank</a>
                <a mat-list-item [href]="baseUrlVar + '/module_attribute_language_list'">Language</a>
                <a mat-list-item [href]="baseUrlVar + '/module_attribute_skills_list'">Skills</a>
            </div>
            <mat-list-item (click)="showSubmenuMetrics = !showSubmenuMetrics" class="parent parent_class">
                <span class="full-width" *ngIf="isExpanded || isShowing">Metrics</span>
                <mat-icon mat-list-icon>auto_graph</mat-icon>
                <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenuMetrics}"
                    *ngIf="isExpanded || isShowing">expand_more</mat-icon>
            </mat-list-item>
            <div class="submenu" [ngClass]="{'expanded' : showSubmenuMetrics}" *ngIf="isShowing || isExpanded">
                <a mat-list-item [href]="baseUrlVar + '/elastic/module-consumption-dashboard'">Modules Consumption</a>
                <a mat-list-item [href]="baseUrlVar + '/leaderboard/by_document'">Document Consumption</a>
                <a mat-list-item [href]="baseUrlVar + '/elastic/module-consumption-dashboard-by-user'">By User</a>
                <a mat-list-item [href]="baseUrlVar + '/infogeon_custom_reports/reports_view'">Make your own</a>
                <a mat-list-item [href]="baseUrlVar + '/infogeon_module/share_doc_report'">Share Doc</a>
                <a mat-list-item [href]="baseUrlVar + '/dashboard/userwise_module_completion'">User wise consumption</a>
                <a mat-list-item [href]="baseUrlVar + '/doc/status/reports'">Likes & Views</a>
                <a mat-list-item [href]="baseUrlVar + '/infogeon_badges/report'">Badges</a>
                <a mat-list-item href="/report"
                    [ngClass]="{child_active : navBarData == '/report', child_inactive : navBarData != '/report'}">Task
                    report</a>
            </div>
            <mat-list-item (click)="showSubmenuMotivate = !showSubmenuMotivate" class="parent parent_class">
                <img class="console_icons" src="assets/console_view_icons/Motivate.svg">
                <span class="full-width" *ngIf="isExpanded || isShowing">Motivate</span>
                <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenuMotivate}"
                    *ngIf="isExpanded || isShowing">expand_more</mat-icon>
            </mat-list-item>
            <div class="submenu" [ngClass]="{'expanded' : showSubmenuMotivate}" *ngIf="isShowing || isExpanded">
                <a mat-list-item [href]="baseUrlVar + '/users/ad-hoc/points'" class="">Ad-hoc points</a>
                <a mat-list-item [href]="baseUrlVar + '/rewards/dashboard'">Awards</a>
            </div>
            <mat-list-item (click)="showSubmenuApproval = !showSubmenuApproval" class="parent parent_class">
                <img class="console_icons" src="assets/console_view_icons/Approval.svg">
                <span class="full-width" *ngIf="isExpanded || isShowing">Approvals</span>
                <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenuApproval}"
                    *ngIf="isExpanded || isShowing">expand_more</mat-icon>
            </mat-list-item>
            <div class="submenu" [ngClass]="{'expanded' : showSubmenuApproval}" *ngIf="isShowing || isExpanded">
                <a mat-list-item [href]="baseUrlVar + '/infogeon_showcase_judge_approval'">Judge Approval</a>
                <a mat-list-item [href]="baseUrlVar + '/infogeon_showcase_approval'">Manager Approval</a>
                <a mat-list-item [href]="baseUrlVar + '/enroll_approval_dashboard'">Module Enroll</a>
            </div>
            <mat-list-item class="parent parent_class parent_without_drop">
                <img class="console_icons" src="assets/console_view_icons/Remind.svg">
                <a [href]="baseUrlVar + '/module_notification/email_reminder_list'"
                    *ngIf="isExpanded || isShowing">Remind</a>
            </mat-list-item>
            <mat-list-item class="nav_section parent parent_class">
                <img class="console_icons" src="assets/console_view_icons/Insights.svg">
                <span class="section_head full-width" *ngIf="isExpanded || isShowing">Bsharp Insights</span>
            </mat-list-item>
            <mat-list-item class="parent parent_class parent_without_drop">
                <img class="console_icons" src="assets/console_view_icons/Forms.svg">
                <a [href]="baseUrlVar + '/infocapture-rlist'" *ngIf="isExpanded || isShowing">Deploy Forms</a>
            </mat-list-item>
            <mat-list-item class="parent parent_class parent_without_drop">
                <img class="console_icons" src="assets/console_view_icons/Forms.svg">
                <a [href]="baseUrlVar + '/infogeon-infocapture/formlist'" *ngIf="isExpanded || isShowing">Form
                    Responses</a>
            </mat-list-item>
            <mat-list-item (click)="showSubmenuAccounts = !showSubmenuAccounts" class="parent parent_class">
                <img class="console_icons" src="assets/console_view_icons/Accounts.svg">
                <span class="full-width" *ngIf="isExpanded || isShowing">Accounts</span>
                <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenuAccounts}"
                    *ngIf="isExpanded || isShowing">expand_more</mat-icon>
            </mat-list-item>
            <div class="submenu" [ngClass]="{'expanded' : showSubmenuAccounts}" *ngIf="isShowing || isExpanded">
                <a mat-list-item [href]="baseUrlVar + '/account-manager-list'">Manage Account</a>
                <a mat-list-item [href]="baseUrlVar + '/manage/account/fields'">Account Attributes</a>
            </div>
            <mat-list-item (click)="showSubmenuLeads = !showSubmenuLeads" class="parent parent_class">
                <img class="console_icons" src="assets/console_view_icons/Leads.svg">
                <span class="full-width" *ngIf="isExpanded || isShowing">Leads</span>
                <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenuLeads}"
                    *ngIf="isExpanded || isShowing">expand_more</mat-icon>
            </mat-list-item>
            <div class="submenu" [ngClass]="{'expanded' : showSubmenuLeads}" *ngIf="isShowing || isExpanded">
                <a mat-list-item [href]="baseUrlVar + '/leads/dashboard'">Manage Leads</a>
                <a mat-list-item [href]="baseUrlVar + '/lead_config'">Configurations</a>
            </div>
            <mat-list-item (click)="showSubmenuFaq = !showSubmenuFaq" class="parent parent_class">
                <img class="console_icons" src="assets/console_view_icons/FAQ.svg">
                <span class="full-width" *ngIf="isExpanded || isShowing">FAQ</span>
                <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenuFaq}" *ngIf="isExpanded || isShowing">
                    expand_more</mat-icon>
            </mat-list-item>
            <div class="submenu" [ngClass]="{'expanded' : showSubmenuFaq}" *ngIf="isShowing || isExpanded">
                <a mat-list-item [href]="baseUrlVar + '/infogeon_infocapture/checklist'">Checklist</a>
                <a mat-list-item [href]="baseUrlVar + '/faq/faq_list'">Manage</a>
                <a mat-list-item [href]="baseUrlVar + '/infogeon_faq/unanswer_faq'">Report</a>
            </div>
            <mat-list-item (click)="showSubmenuSku = !showSubmenuSku" class="parent parent_class">
                <img class="console_icons" src="assets/console_view_icons/SKUs.svg">
                <span class="full-width" *ngIf="isExpanded || isShowing">Product SKUs</span>
                <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubmenuSku}" *ngIf="isExpanded || isShowing">
                    expand_more</mat-icon>
            </mat-list-item>
            <div class="submenu" [ngClass]="{'expanded' : showSubmenuSku}" *ngIf="isShowing || isExpanded">
                <a mat-list-item [href]="baseUrlVar + '/manage/code'">Manage SKUs</a>
                <a mat-list-item [href]="baseUrlVar + '/manage/code/attributes'">SKU Attributes</a>
            </div>
        </mat-nav-list>

    </mat-sidenav>

    <div class="console-sidenav-content">
        <router-outlet></router-outlet>
    </div>

</mat-sidenav-container>