<div class="row noMargin create_container">
    <div class="col-4 noPadding flexClass alignSelfCenter">
        <a href="https://apps.bsharpcorp.com/users_dashboard" style="color:#000000">
            <img class="back_arrow alignSelfCenter" src="/assets/task_manager_icons/task_manager_back_arrow.svg" alt="">
        </a>
        <span class="mainHeading ml-2 alignSelfCenter" *ngIf="!isMobile">Home</span>
    </div>
<!--    <div class="col-8 noPadding flexClass alignSelfCenter" style="justify-content: end;">-->
<!--        <button class="secondaryBtn mr-2 blue_bg">Download sessions</button>-->
<!--        <button class="primaryBtn" routerLink="/classroom/create_session">Create session</button>-->
<!--    </div>-->
    <div class="col-12 noPadding filter_sec normal_mt">
        <form [formGroup]="formGroup" (ngSubmit)="filterSession()">
            <div class="row noMargin filter_head">
                <div class="col-6 col-md-8 noPadding alignSelfCenter">
                    <span class="alignSelfCenter subHeading half_mr alignSelfCenter">Completed sessions</span>
                    <span class="count_badge alignSelfCenter">{{completed_count}}</span>
                </div>
                <!-- <div class="col-6 col-md-4 noPadding text-right alignSelfCenter">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control primaryText" placeholder="Search">
                    </div>
                </div> -->
            </div>
            <div class="row noMargin filter_body">
                <div class="filters">
                    <div class="small_device_margin input-group input_noBorder calendar_grp input_cust alignSelfCenter noMargin half_pr mob_width">
                        <button class="btn btn-outline-secondary calendar_btn flexClass ht_class" (click)="d.toggle()" type="button">
                            <i class="fa fa-calendar alignSelfCenter"></i>
                        </button>
                        <input
                            class="form-control ht_class date_input"
                            name="dp"
                            placeholder="From date"
                            formControlName="date"
                            ngbDatepicker
                            #d="ngbDatepicker"
                            [(ngModel)]="model"
                        />

                    </div>
                    <div class="small_device_margin input-group input_noBorder calendar_grp input_cust alignSelfCenter noMargin half_pr mob_width">
                        <button class="btn btn-outline-secondary calendar_btn flexClass ht_class" (click)="d1.toggle()" type="button">
                            <i class="fa fa-calendar alignSelfCenter"></i>
                        </button>
                        <input
                            class="form-control ht_class date_input"
                            name="dp"
                            placeholder="To date"
                            formControlName="to_date"
                            ngbDatepicker
                            #d1="ngbDatepicker"
                            [(ngModel)]="to_date"
                        />

                    </div>
                    <!-- <div class="form-group noMargin half_pr mob_width">
                        <igx-date-picker class="input_border input_cust small_device_margin date_picker" formControlName="date"
                            mode="dropdown">
                            <label igxLabel>Session date</label>
                        </igx-date-picker>
                    </div> -->
                    <!-- <div class="form-group noMargin half_pr small_device_margin mob_width flexClass">
                        <angular2-multiselect [data]="moduleList" formControlName="module"
                            [settings]="moduleDropdownSettings"></angular2-multiselect>
                    </div> -->
                    <!-- <div class="form-group noMargin half_pr small_device_margin mob_width">
                        <igx-select class="input_border primaryText input_cust" formControlName="type"
                            placeholder="Select">
                            <label igxLabel>Filter by type</label>
                            <igx-select-item value="0">Online</igx-select-item>
                            <igx-select-item value="1">Offline</igx-select-item>
                            <igx-select-item value="2">Online & Offline</igx-select-item>
                        </igx-select>
                    </div> -->
                    <!-- <div class="form-group noMargin half_pr small_device_margin mob_width flexClass">
                        <angular2-multiselect [data]="trainerList" formControlName="trainer"
                            [settings]="trainerDropdownSettings"></angular2-multiselect>
                    </div> -->
                    <div class="form-group noMargin half_pr small_device_margin mob_width">
                        <igx-select class="input_border primaryText input_cust" formControlName="status"
                            placeholder="Select">
                            <label igxLabel>Filter by status</label>
                            <igx-select-item value="0">Open</igx-select-item>
                            <igx-select-item value="1">Enrolled</igx-select-item>
                            <igx-select-item value="2">Completed</igx-select-item>
                            <igx-select-item value="3">Not completed</igx-select-item>
                        </igx-select>
                    </div>
                    <div class="form-group noMargin half_pr small_device_margin mob_width" *ngIf="!isDtForApply">
                        <button *ngIf="isFilterApplied" type="button" (click)="resetBtnClick()" class="resetBtn mr-2 alignSelfCenter primaryText">Reset</button>
                        <button class="secondaryBtn mr-2 blue_bg alignSelfCenter">Apply</button>
                    </div>

                </div>
                <div class="button flexClass alignSelfCenter" *ngIf="isDtForApply">
                    <button *ngIf="isFilterApplied" type="button" (click)="resetBtnClick()" class="resetBtn mr-2 alignSelfCenter primaryText">Reset</button>
                    <button class="secondaryBtn mr-2 blue_bg alignSelfCenter">Apply</button>
                </div>

            </div>
        </form>
    </div>
    <div class="col-12 noPadding list_sec">
        <div class="table-wrapper" *ngIf="tableData.length != 0">
            <div class="table noMargin">
                <div class="row noMargin table_row">
                    <div class="cell date_flex primaryText">Schedule</div>
                    <div class="cell main_flex primaryText">Classroom session</div>
                    <div class="cell status_flex primaryText" *ngIf="!isMobile">Trainer</div>
                    <div class="cell status_flex primaryText" *ngIf="!isMobile">Status</div>
                </div>
                <div class="row noMargin" *ngFor="let item of tableData |paginate: {itemsPerPage: itemsPerPage,
                    currentPage: currentPage, totalItems: totalCount }; let i = index">
                    <div class="col-12 noPadding">
                        <div class="row noMargin table_section" *ngIf="i == 0 && item.length != 0">Upcoming</div>
                        <div class="row noMargin table_section" *ngIf="i == 1 && item.length != 0">Closed</div>
                        <div class="row noMargin table_body_row pointer" *ngFor="let data of item"
                            routerLink="/classroom/view_session/{{data.sid}}">
                            <div class="cell date_flex">
                                <app-date-chip *ngIf="!isMobile" [dateData]="data.time_data"></app-date-chip>
                                <div class="subHeading" *ngIf="isMobile">
                                    {{data.time_data.session_date | date:'dd'}} {{data.time_data.session_date |
                                    date:'MMM'}}
                                </div>
                                <div class="smallText" *ngIf="isMobile">{{data.time_data.session_date | date:'EEE'}}
                                </div>
                                <div class="primaryText" *ngIf="isMobile">
                                    {{data.time_data.from_time | date:'h:mm a'}} - {{data.time_data.to_time | date:'h:mm
                                    a'}}
                                </div>
                                <div class="smallText" *ngIf="isMobile">{{data.time_data.timezone}}</div>
                            </div>
                            <div class="cell main_flex truncate">
                                <div class="row noMargin">
                                    <span class="primaryText session_title truncate">{{data.module_name}}</span>
                                </div>
                                <div class="row noMargin primaryText session_details truncate">
                                    <span class="onNoff_chip common_chip alignSelfCenter"
                                        *ngIf="data.classroom_type == 2">Online & offline</span>
                                    <span class="online_chip common_chip alignSelfCenter"
                                        *ngIf="data.classroom_type == 0">Online</span>
                                    <span class="offline_chip common_chip alignSelfCenter"
                                        *ngIf="data.classroom_type == 1">Offline</span>
                                    <span class="skill_type alignSelfCenter col noPadding truncate">
                                        {{data.session_name}}
                                    </span>
                                </div>
                                <div class="row noMargin primaryText session_details">
                                    <span class="smallText alignSelfCenter mr-2" *ngIf="isMobile">
                                        Trainer: {{data.created_by}}
                                    </span>
                                    <span class="smallText alignSelfCenter" *ngIf="isMobile">
                                        Status:
                                        <span class="green_text" *ngIf="data.user_status == 'enrolled'">Enrolled</span>
                                        <span class="red_text" *ngIf="data.user_status == 'not completed'">Not
                                            completed</span>
                                        <span *ngIf="data.user_status == 'completed'">Completed</span>
                                    </span>
                                    <button class="primaryBtn orange_btn alignSelfCenter" (click)="enrollSession($event,data.sid)"
                                        *ngIf="isMobile && data.user_status == 'enroll now'">
                                        Enroll now
                                    </button>
                                </div>
                            </div>
                            <div class="cell status_flex primaryText" *ngIf="!isMobile">{{data.created_by}}</div>
                            <div class="cell status_flex primaryText" *ngIf="!isMobile">
                                <span class="green_text" *ngIf="data.user_status == 'enrolled'">Enrolled</span>
                                <span class="red_text" *ngIf="data.user_status == 'not completed'">Not completed</span>
                                <span *ngIf="data.user_status == 'completed'">Completed</span>
                                <button class="primaryBtn orange_btn alignSelfCenter"
                                    (click)="enrollSession($event,data.sid)" *ngIf="data.user_status == 'enroll now'">
                                    Enroll now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-12 noPadding text-center">
                <pagination-controls class="my-pagination normal_mt" responsive="true" previousLabel=" " nextLabel=" "
                    (pageChange)="pageChange($event)" autoHide="true" directionLinks="true" maxSize="9"
                    [(currentPage)]="currentPage" [itemsPerPage]="itemsPerPage" [totalItems]="totalCount">
                </pagination-controls>
            </div>
        </div>
        <div class="default_img_sec col-12 noPadding text-center flexClass" *ngIf="tableData.length == 0">
            <div class="inner_class alignSelfCenter mx-auto">
                <img class="default_img_attendence" src="assets/classroom/images/user_session_default.png" alt="">
                <div class="default_text subHeading mt-2">
                    No classroom session is in progress or scheduled yet
                </div>
            </div>

        </div>
    </div>
</div>
