import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { baseUrl } from 'src/app/config/api';
import { CookieService } from 'ngx-cookie-service';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';



@Injectable({
  providedIn: 'root'
})
export class TaskManagementService {

  private subject = new BehaviorSubject({});
  private cardSubject = new BehaviorSubject({});
  private cardCountSubject = new BehaviorSubject({});
  public newTaskSubject = new Subject<any>();
  public updateTaskCount = new Subject<any>();

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  httpOptions = {

    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'X-CSRFToken': this.cookieService.get('csrftoken'),
    }),
    withCredentials: true
  };

  create(data): Observable<any> {
    return this.http.post(baseUrl + '/schedule/addReminder', data, this.httpOptions);
  }

  getCalendarData(date): Observable<any> {
    return this.http.get(baseUrl + '/schedule/getCalendarData/' + date, this.httpOptions);
  }

  getMyTaskList(data): Observable<any> {
    return this.http.post(baseUrl + '/task/list', data, this.httpOptions);
    // return this.http.get(baseUrl+'/task/list');
  }

  //Upadte Task
  myTaskUpdate(data): Observable<any> {
    console.log(data);
    return this.http.post(baseUrl + '/task/task-update/' + data.task_id , data, this.httpOptions);
  }

  //Upadte Task
  myTaskDelete(data): Observable<any> {
    console.log(data);
    return this.http.get(baseUrl + '/task/task-delete/' + data.task_id, this.httpOptions);
  }

  getModuleList(): Observable<any> {
    return this.http.get(baseUrl + '/schedule/moduleList', this.httpOptions);
  }

  getFormList(): Observable<any> {
    return this.http.get(baseUrl + '/schedule/formList', this.httpOptions);
  }

  getUserList(): Observable<any> {
    return this.http.get(baseUrl + '/schedule/userList', this.httpOptions);
  }

  getUserDelegateList(task_id): Observable<any> {
    return this.http.get(baseUrl + '/schedule/getUserDelegateList/' + task_id, this.httpOptions);
  }

  getGroupList(): Observable<any> {
    return this.http.get(baseUrl + '/schedule/groupList', this.httpOptions);
  }

  submitTask(data): Observable<any> {
    console.log(JSON.stringify(data))
    return this.http.post(baseUrl + '/task/add', JSON.stringify(data), this.httpOptions);
    // return this.http.post(baseUrl+ '/task/add', data);
  }

  assignedTaskSubmit(data): Observable<any> {
    console.log(data);
    return this.http.post(baseUrl + '/task/assignedTaskSubmit', data, this.httpOptions);
  }



  getAssignedToMeTask(data): Observable<any> {
    return this.http.post(baseUrl + '/task/getAssignedToMeTask', data, this.httpOptions);
    // return this.http.get(baseUrl+'/task/list');
  }

  getAssignedByMeTask(data): Observable<any> {
    return this.http.post(baseUrl + '/task/getAssignedByMeTask', data, this.httpOptions);
  }



  getAllTask(data): Observable<any> {
    return this.http.post(baseUrl + '/task/getAllTask', data, this.httpOptions);
  }



  getAllUserTask(task_id): Observable<any> {
    return this.http.get(baseUrl + '/task/getAllUserTask/' + task_id, this.httpOptions);
  }

  addAcceptTask(data): Observable<any> {
    return this.http.post(baseUrl + '/task/addAcceptTask', data, this.httpOptions);
  }


  updateAcceptTask(data): Observable<any> {
    console.log(data);
    return this.http.post(baseUrl + '/task/updateAcceptTask/' + data.task_id,  data, this.httpOptions);
  }

  addDeclineTask(data): Observable<any> {
    return this.http.post(baseUrl + '/task/addDeclineTask', data, this.httpOptions);
  }

  updateDeclineTask(data): Observable<any> {
    console.log(data);
    return this.http.post(baseUrl + '/task/updateDeclineTask/' + data.task_id , data, this.httpOptions);
  }

  taskUserGrpSubmit(groupArray, task_id): Observable<any> {
    return this.http.post(baseUrl + '/task/taskUserGrpSubmit/' + task_id, groupArray, this.httpOptions);
  }

  taskUserIndividualSubmit(indArray, task_id): Observable<any> {
    return this.http.post(baseUrl + '/task/taskUserIndividualSubmit/' + task_id, indArray, this.httpOptions);
  }

  taskUserToMeSubmit(data): Observable<any> {
    return this.http.post(baseUrl + '/task/taskUserToMeSubmit', data, this.httpOptions);
  }

  sendEditData(params) {
    //debugger
    this.subject.next({ data: params });

  }

  getEditData(): Observable<any> {
    return this.subject.asObservable();
  }


  editTask(data): Observable<any> {
    return this.http.get(baseUrl + '/task/editTask/' + data, this.httpOptions);
  }

  getCreatedUserDetails(uid): Observable<any> {
    return this.http.get(baseUrl + '/task/getCreatedUserDetails/' + uid, this.httpOptions);
  }
  getFormDetails(fid): Observable<any> {
    return this.http.get(baseUrl + '/task/getFormDetails/' + fid, this.httpOptions);
  }
  getModuleDetails(mid): Observable<any> {
    return this.http.get(baseUrl + '/task/getModuleDetails/' + mid, this.httpOptions);
  }


  moduleCompletedStatus(mid): Observable<any> {
    return this.http.get(baseUrl + '/task/moduleCompletedStatus/' + mid, this.httpOptions);
  }

  FormCompletedStatus(nid): Observable<any> {
    return this.http.get(baseUrl + '/task/FormCompletedStatus/' + nid, this.httpOptions);
  }

  getAssignTaskData(task_id): Observable<any> {
    return this.http.get(baseUrl + '/task/getAssignTaskData/' + task_id, this.httpOptions);
  }

  getAssignedIndividuals(task_id): Observable<any> {
    return this.http.get(baseUrl + '/task/getAssignedIndividuals/' + task_id, this.httpOptions);
  }

  getAssignedGroups(task_id): Observable<any> {
    return this.http.get(baseUrl + '/task/getAssignedGroups/' + task_id, this.httpOptions);
  }
  deleteOldAssign(task_id): Observable<any> {
    return this.http.get(baseUrl + '/task/deleteOldAssign/' + task_id, this.httpOptions);
  }

  getReminders(task_id): Observable<any> {
    return this.http.get(baseUrl + '/task/getReminders/' + task_id, this.httpOptions);
  }

  updateReminder(data): Observable<any> {
    return this.http.post(baseUrl + '/task/updateReminder/' + data.task_id , data, this.httpOptions);
  }

  updateTaskAssign(data): Observable<any> {
    return this.http.post(baseUrl + '/task/updateTaskAssign/' + data.task_id , data, this.httpOptions);
  }

  updateTaskAssignUsers(data): Observable<any> {
    return this.http.post(baseUrl + '/task/updateTaskAssignUsers/' + data.task_id, data, this.httpOptions);
  }

  deleteReminder(data): Observable<any> {
    return this.http.post(baseUrl + '/task/deleteReminder/' + data.task_id , data, this.httpOptions);
  }

  getAssignedAccounts(): Observable<any> {
    return this.http.get(baseUrl + '/task/getAssignedAccounts', this.httpOptions);
  }

  assignedTaskUpdate(data): Observable<any> {
    console.log(data);
    return this.http.post(baseUrl + '/task/assignedTaskUpdate/' + data.task_id , data, this.httpOptions);
  }

  taskUserGrpUpdate(groupArray, task_id): Observable<any> {
    return this.http.post(baseUrl + '/task/taskUserGrpUpdate/' + task_id, groupArray, this.httpOptions);
  }

  taskUserIndividualUpdate(indArray, task_id): Observable<any> {
    return this.http.post(baseUrl + '/task/taskUserIndividualUpdate/' + task_id, indArray, this.httpOptions);
  }


  taskUserToMeUpdate(data): Observable<any> {
    return this.http.post(baseUrl + '/task/taskUserToMeUpdate/' + data.task_id , data, this.httpOptions);
  }

  getTaskListWitFilter(data): Observable<any> {
    return this.http.post(baseUrl + '/task/getTaskListWitFilter', data, this.httpOptions);
    // return this.http.get(baseUrl+'/task/list');
  }

  sendDelegateData(params) {
    this.subject.next({ data: params });
  }

  getDelegateData(): Observable<any> {
    return this.subject.asObservable();
  }

  getTaskDetails(data): Observable<any> {
    return this.http.get(baseUrl + '/task/getTaskDetails/' + data, this.httpOptions);
  }

  saveDelegate(data): Observable<any> {
    return this.http.post(baseUrl + '/task/saveDelegate', data, this.httpOptions);
  }

  getTaskCount(): Observable<any> {
    return this.http.get(baseUrl + '/task/getTaskCount', this.httpOptions);
  }


  getDelegated_task(data): Observable<any> {
    return this.http.post(baseUrl + '/task/getDelegatedTask', data, this.httpOptions);
  }


  getSearchData(data): Observable<any> {
    return this.http.post(baseUrl + '/task/searchTask', data, this.httpOptions);
  }

  updateUserTask(data): Observable<any> {
    return this.http.post(baseUrl + '/task/updateUserTask/' + data.task_id , data, this.httpOptions);
  }

  addNewTaskSubject() {
    this.newTaskSubject.next("Update Task");
  }

  updateTaskCountService() {
    this.updateTaskCount.next("Hey")
  }

  loginUserDetails(): Observable<any> {
    return this.http.get(baseUrl + '/user/loginUserDetails', this.httpOptions);
    // return this.http.get(baseUrl+'/task/list');
  }


  getCompletedTask(data): Observable<any> {
    return this.http.post(baseUrl + '/task/getCompletedTask', data, this.httpOptions);
  }

  // getTaskDownloadData(taskId): Observable<any> {
  //   return this.http.get(baseUrl + '/task/getTaskDownloadData/' + taskId, this.httpOptions);
  // }

  getTaskDownloadData(taskId): Observable<any> {
    return this.http.get(`${baseUrl}/task/getTaskDownloadData/${taskId}`, this.httpOptions);
  }



  getTimeZone(): Observable<any> {
    return this.http.get(baseUrl + '/schedule/getTimeZone', this.httpOptions);
  }

  getSelectedZone(task_id): Observable<any> {
    return this.http.get(baseUrl + '/task/getSelectedZone/' + task_id, this.httpOptions);
  }

  getFilterTaskReport(data): Observable<any> {
    return this.http.post(baseUrl + '/task/getFilterTaskReport', data, this.httpOptions);
  }

  getTaskDownloadFilerData(data): Observable<any> {
    return this.http.post(baseUrl + '/task/getTaskDownloadFilerData', data, this.httpOptions);
  }

  add_task_sheet_data(data): Observable<any> {
    return this.http.post(baseUrl + '/task/add_task_sheet_data', data, this.httpOptions);
  }

  sendCardData(params) {
    this.cardSubject.next({ data: params });
  }

  getCardData(): Observable<any> {
    return this.cardSubject.asObservable();
  }

  sendCardClear(params) {
    this.cardSubject.next({ data: params });
  }

  getCardClear(): Observable<any> {
    return this.cardSubject.asObservable();
  }


  sendCardCounts(params) {
    this.cardCountSubject.next({ data: params });
  }

  getCardCardCounts(): Observable<any> {
    return this.cardCountSubject.asObservable();
  }



  getCardTask(data): Observable<any> {
    return this.http.post(baseUrl + '/task/getCardTask', data, this.httpOptions);
    // return this.http.get(baseUrl+'/task/list');
  }

  getAdminCardTask(data): Observable<any> {
    return this.http.post(baseUrl + '/task/getAdminCardList', data, this.httpOptions);
    // return this.http.get(baseUrl+'/task/list');
  }

  getLoggedInUserRole(): Observable<any> {
    return this.http.get(baseUrl + '/task/getLoggedInUserRole', this.httpOptions);
  }

  getSelectedAccount(account_id): Observable<any> {
    return this.http.get(baseUrl + '/task/getSelectedAccount/' + account_id, this.httpOptions);
  }


  saveTaskType(data): Observable<any> {
    return this.http.post(baseUrl + '/task/saveTaskType', data, this.httpOptions);
  }



  getTaskTypes(): Observable<any> {
    return this.http.get(baseUrl + '/task/getTaskTypes', this.httpOptions);
  }


  getCompanyTaskType(): Observable<any> {
    return this.http.get(baseUrl + '/task/getCompanyTaskType', this.httpOptions);
  }

  updateTaskType(data): Observable<any> {
    return this.http.post(baseUrl + '/task/updateTaskType/' + data.id , data, this.httpOptions);
  }

  getEditTaskType(id): Observable<any> {
    return this.http.get(baseUrl + '/task/getEditTaskType/' + id, this.httpOptions);
  }

  getReportCard(): Observable<any> {
    return this.http.get(baseUrl + '/task/getReportCard', this.httpOptions);
  }
  getAttrList(): Observable<any> {
    return this.http.get(baseUrl + '/task/getAttrList', this.httpOptions);
  }

  saveAttributes(data): Observable<any> {
    return this.http.post(baseUrl + '/task/saveAttributes', data, this.httpOptions);
  }


  getAssignedAttributes(): Observable<any> {
    return this.http.get(baseUrl + '/task/getAssignedAttributes', this.httpOptions);
  }


  getAdminFilterList(data): Observable<any> {
    return this.http.post(baseUrl + '/task/getAdminFilterList', data, this.httpOptions);
  }

  downloadAdminFilterData(data): Observable<any> {
    return this.http.post(baseUrl + '/task/downloadAdminFilterData', data, this.httpOptions);
  }

  closeTaskCreated(data): Observable<any> {
    return this.http.post(baseUrl + '/task/closeTaskCreated', data, this.httpOptions);
  }

  generateOtpToEmail(email): Observable<any> {
    console.log(email)
    return this.http.get(baseUrl + '/task/generateOtpToEmail/'+ email, this.httpOptions);
  }

  verifyEmailOtp(): Observable<any> {
    console.log('service file')
    return this.http.get(baseUrl + '/task/verifyEmailOtp', this.httpOptions)
  }

  EmailSubscribe(uid): Observable<any>{
    return this.http.get(baseUrl+ '/task/emailSubscribe/' + uid, this.httpOptions);
  }

  EmailUnSubscribe(uid): Observable<any>{
    console.log('sevice uid', uid)
    return this.http.get(baseUrl+ '/task/emailUnSubscribe/' + uid, this.httpOptions);
  }





  /*downloadUserReport(taskId,task_user_id): Observable<any> {
  return this.http.get(`${baseUrl}/task/downloadUserReport/${taskId}/${task_user_id}`, this.httpOptions);
}*/
}
