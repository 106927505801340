import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Component,OnInit,Output,EventEmitter,ViewChild } from '@angular/core';
import { TaskManagementService } from 'src/app/services/task-management.service';
import { ExcelServicesService } from 'src/app/services/excel-services.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup,FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { GlobalConstants } from 'src/app/global-const';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as alertify from 'alertifyjs';
import { IgxExpansionPanelComponent } from "igniteui-angular";
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx';
// AOA : array of array
type AOA = any[][];

@Component({
  selector: 'app-task-bulk-upload',
  templateUrl: './task-bulk-upload.component.html',
  styleUrls: ['../task-reminder/task-reminder.component.scss', '../task-create/task-create.component.css', '../task-view/task-view.component.css','./task-bulk-upload.component.css']
})
export class TaskBulkUploadComponent implements OnInit {

	refExcelData: Array<any>;
  excelFirstRow = [];
  excelDataEncodeToJson;
  excelTransformNum = [];

  uploadShowTblSettings: any;
  uploadShowTbiList: any;
  unit: any

  uploadDataInput: any;
  downloadTemplatebtn: any;
  isDataIsUploaded: any;
  error_excel=false;
  err_response = [];

	/** Default  excel file-name  */
  origExcelData: AOA = [
    ['Data: 2018/10/26'],
    ['Data: 2018/10/26'],
    ['Data: 2018/10/26'],
  ];
  sheetJsExcelName = 'null.xlsx';

  /* excel sheet.js */
  sheetCellRange;
  sheetMaxRow;
  localwSheet;
  localWorkBook;
  localPDF;
  sheetNameForTab: Array<string> = ['excel tab 1', 'excel tab 2'];
  totalPage = this.sheetNameForTab.length;
  selectDefault;
  sheetBufferRender;
  criteriaType: any;
  userRole:any;


  constructor(private taskService: TaskManagementService,
		public datepipe: DatePipe,
		private modalService: NgbModal,
		private excelService: ExcelServicesService,
		private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
   this.getLoggedInUserRole();
  }

   getLoggedInUserRole() {
		this.taskService.getLoggedInUserRole().subscribe(res => {
			this.userRole = res;
		})
	}



  inputExcelOnClick(evt) {
    const target: HTMLInputElement = evt.target;
    if (target.files.length === 0) {
      throw new Error('Exception error');
    }
    if (target.files.length > 1) {
      throw new Error('Cannot use multiple files');
    }
    this.sheetJsExcelName = evt.target.files.item(0).name;
    const reader: FileReader = new FileReader();
    this.readerExcel(reader);
    reader.readAsArrayBuffer(target.files[0]);
    this.sheetBufferRender = target.files[0];

  }

   readerExcel(reader, index = 0) {
    /* reset array */
    this.origExcelData = [];
    reader.onload = (e: any) => {
      const data: string = e.target.result;
      const wBook: XLSX.WorkBook = XLSX.read(data, { type: 'array' });
      this.localWorkBook = wBook;
      const wsname: string = wBook.SheetNames[index];
      this.sheetNameForTab = wBook.SheetNames;
      this.totalPage = this.sheetNameForTab.length;
      this.selectDefault = this.sheetNameForTab[index];
      const wSheet: XLSX.WorkSheet = wBook.Sheets[wsname];
      this.localwSheet = wSheet;
      this.sheetCellRange = XLSX.utils.decode_range(wSheet['!ref']);
      this.sheetMaxRow = this.sheetCellRange.e.r;
      this.origExcelData = <AOA>XLSX.utils.sheet_to_json(wSheet, {
        header: 1,
        range: wSheet['!ref'],
        raw: true,
      });
      this.refExcelData = this.origExcelData.slice(1).map(value => Object.assign([], value));
      /* range &  A->Z */
      this.excelTransformNum = [];
      for (let idx = 0; idx <= this.sheetCellRange.e.c; idx++) {
        this.excelTransformNum[idx] = this.transform(idx);
      }
       this.excelDataEncodeToJson = this.refExcelData.slice(0).map(item =>
        item.reduce((obj, val, i) => {
          obj[this.excelTransformNum[i]] = val;
          return obj;
        }, {}),
      );
      this.uploadShowTbiList = [];
      this.uploadShowTbiList = this.excelDataEncodeToJson;
      console.log('excelDataEncodeToJson', this.excelDataEncodeToJson);
    };
  }

   transform(value) {
    return (value >= 26 ? this.transform(((value / 26) >> 0) - 1) : '') + 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[value % 26 >> 0];
  }

   downloadProcced(content) {
    this.modalService.open(content, { size: 'lg' });
  }


 	excel: any = [{
			Task:'',
			Assigned_To:'',
			Account_ID:'',
			Account_Name:'',
			Task_Type: '',
			Description:'',
			Address:'',
			Customer_ID:'',
			Customer_Name:'',
			Photo:'',
			Signature:'',
		}
	]

  downloadTemplate() {

  this.excelService.exportAsExcelFile(this.excel, 'sample');

  }

  submitUploadSheetData() {
            this.err_response = [];
            let data_collection = []
            this.excelDataEncodeToJson.forEach(function (item: any) {

              data_collection.push({
                task_name: item.A ? item.A : '' ,
                assigned_to: item.B ? item.B : '',
                account_id: item.C ? item.C : '',
                account_name: item.D ? item.D : '',
                task_type: item.E ? item.E : '',
                description: item.F ? item.F : '',
                address: item.G ? item.G : '',
                customer_id: item.H ? item.H : '',
                customer_name: item.I ? item.I : '',
                photo: item.J ? item.J : '',
                signature: item.K ? item.K : '',
                task_created: Math.trunc((new Date()).getTime() / 1000),

              });
            });

            //Add KPI Sheet Data

            this.taskService.add_task_sheet_data(data_collection)
              .subscribe(
                response => {
                  if (response != '') {
                    this.error_excel = true;
                    this.err_response = response;
                    alertify.error('Upload excel again!');
                   }else{
                      alertify.success('Task uploaded successfully!');
                    }
                    this.isDataIsUploaded = true;

                },
                error => {
                  console.log(error);
                });




  }
  download_error_excel(){
  var response = this.err_response;
   let user_collect: any[] = [];
   if (response.length !== 0) {
          response.forEach(function (item: any) {
            user_collect.push({ Error: item.error});
          });

    this.excelService.exportAsExcelFile(user_collect, 'error');
  }
}


}
