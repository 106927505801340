import { Utils } from './config/utils';
import { DOCUMENT, Location } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { TaskManagementService } from 'src/app/services/task-management.service'
import { GlobalConstants } from 'src/app/global-const'
import { navbar_const } from 'src/app/config/api';
import { MatSidenav } from '@angular/material/sidenav';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  constructor(private taskService: TaskManagementService, @Inject(DOCUMENT) private document: Document, private location: Location,  private cookieService: CookieService) { }

  navBarData: string = '';
  searchNav: any = ''
  userData: any = {
    'company_img': ''
  }
  company_logo: any = 0
  navBarLink = navbar_const;
  isContentView = true;
  baseUrlVar: string = '';

  baseUrlVar_cookie: string = '';

  toggleSidenav(){
    this.baseUrlVar_cookie = this.cookieService.get('base_url');
    // Set navBarLink based on baseUrlVar_cookie
    if (this.baseUrlVar_cookie && this.baseUrlVar_cookie.trim() !== '') {
        this.navBarLink = this.baseUrlVar_cookie;
    } else {
        // Set to navbar_const if baseUrlVar_cookie is empty
        this.navBarLink = this.navBarLink;
    }
    
    // Toggle the sidenav
    this.sidenav.toggle();
  }
  
  redirectSearch() {
    
    this.baseUrlVar_cookie = this.cookieService.get('base_url');
     // Set navBarLink based on baseUrlVar_cookie
     if (this.baseUrlVar_cookie && this.baseUrlVar_cookie.trim() !== '') {
        this.navBarLink = this.baseUrlVar_cookie;
    } else {
        // Set to navbar_const if baseUrlVar_cookie is empty
        this.navBarLink = this.baseUrlVar;
    }

    var sParameter = '';
    if (this.searchNav != '') {
      sParameter = encodeURIComponent(this.searchNav.trim())
      console.log(sParameter)
      this.document.location.href = `${this.navBarLink}/users_dashboard/search/${sParameter}`;
    }
  }

  ngOnInit(): void {
    // console.log(this.location.path())
   
    this.baseUrlVar = navbar_const;
    this.getLogedinUserData();
    this.navBarData = this.location.path();

    //For content view
    if (this.navBarData == '/taskmanagement') {
      this.isContentView = true;
    }

    //For console view
    if (this.navBarData == '/report') {
      this.isContentView = false;
    } else if (this.navBarData == '/task-bulk-upload') {
      this.isContentView = false;
    }

    this.baseUrlVar_cookie = this.cookieService.get('base_url');

    // Set navBarLink based on baseUrlVar_cookie
    if (this.baseUrlVar_cookie && this.baseUrlVar_cookie.trim() !== '') {
      this.navBarLink = this.baseUrlVar_cookie;
    } else {
      // Set to navbar_const if baseUrlVar_cookie is empty
        this.navBarLink = this.baseUrlVar;
    }
    
  }

  title = 'infogeonAngularAdHoc';

  getLogedinUserData() {

    this.company_logo = 0;
    this.userData.company_img = '../../../../assets/Bsharp-Logo-regulars.png';

    let userDataPromise = new Promise((resolve, reject) => {
      this.taskService.loginUserDetails().toPromise().then(res => {

        console.log("user res", res)

        GlobalConstants.userDetails = res;
        this.userData = res;
        this.userData.company_img = Utils.aws_file_create(this.userData.logo_image_name);
        this.userData.profile_img = Utils.aws_file_create(this.userData.user_avt);

      })
    })
  }
}

