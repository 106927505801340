<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1,user-scalable=0"/>
<div class="card main_card">
  <div class="col-md-12 header_main_div noPadding">
    <h4 class="panel-title">
      <span class="panelTitle noPadding">
        <i class="fa fa-bar-chart-o"></i>&nbsp;Task Management report
      </span>
    </h4>
  </div>
  <span class="span_msg" *ngIf="userRole == 3">You are not authorised to access this page.</span>
  <div class="col-md-12 filter_main_div" *ngIf="userRole != 3">
    <span class="standard_black filter_by_span"> Filter by</span>


     <div class="row">
        <div class="col-md-3">
          <div class="filter_drop_divs">
            <div class="form-group">
               <div class="input-group">
                  <input class="form-control" name="fromDate" [(ngModel)]="fromDate" placeholder="From Date"
                    onfocus="(this.type='date')" type="text" onblur="(this.type='text')" id="d1">
<!--                  <div class="input-group-append">-->
<!--                     <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>-->
<!--                  </div>-->

               </div>
            </div>
           </div>
        </div>
          <div class="col-md-3">
            <div class="filter_drop_divs">
            <div class="form-group">
               <div class="input-group">
                  <input class="form-control"  name="toDate" [(ngModel)]="toDate" placeholder="To Date"
                    onfocus="(this.type='date')" type="text" onblur="(this.type='text')">
<!--                  <div class="input-group-append">-->
<!--                     <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>-->
<!--                  </div>-->
               </div>
            </div>
           </div>
          </div>
        <div class="col-md-3  filter_drop_div" style="padding: 0 5px;">
            <select class="form-control filter_drop"
                    name="filter_task_type"
                    [(ngModel)]="filter_task_type">
              <option value="0">Task Type</option>
              <option *ngFor="let taskTypes of task_type_set;" [value]="taskTypes.id">{{taskTypes.task_type}}</option>
            </select>
        </div>
        <div class="col-md-3 filter_drop_div" style="padding: 0 5px;">
          <div class="filter_drop_divs">
             <div class="form-group">
            <angular2-multiselect [data]="accountdropdownList" [(ngModel)]="accountSelect"
                         [settings]="accountDropdownSettings"></angular2-multiselect>
           </div>
          </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-3">
          <div class="filter_drop_divs">
             <div class="form-group">
          <angular2-multiselect  [data]="userdropdownList" [(ngModel)]="assignToUserSelect"
    [settings]="toMeDropdownSettings"></angular2-multiselect>
        </div>
           </div>
        </div>
          <div class="col-md-3">
            <div class="filter_drop_divs">
            <div class="form-group">
               <angular2-multiselect [data]="userdropdownList" [(ngModel)]="assignByUserSelect"
    [settings]="byMeDropdownSettings"></angular2-multiselect>
            </div>
           </div>
          </div>

    </div>


    <div class="col-md-12 text-right noPadding">
<button type="button" class="btn btn-teel-blue apply_btn" (click)="applyFilter()">Apply</button>
<button *ngIf="task_filter_applied == true" type="button" class="btn btn-teel-blue reset_btns" (click)="clearFilter()">Reset</button>
<button *ngIf="task_filter_applied == true" type="button" class="btn btn-teel-blue download_btn" (click)="downloadAll()">Download</button>


<div class="dropdown" style="margin-top: 20px;">
  <button class="btn btn-teel-blue download_btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    New Task
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <a class="dropdown-item" href="#">Add task</a>
    <a class="dropdown-item" href="task-bulk-upload">Add multiple Task</a>
  </div>
</div>



<div class="marginTop" *ngIf="task_filter_applied == true">
<div class="table-responsive">
<table class="table table-striped">
  <thead>
    <tr>
      <th scope="col">Task ID</th>
      <th scope="col">Task</th>
      <th scope="col">Assigned by</th>
      <th scope="col">Assigned to</th>
      <th scope="col">Assigned on</th>
      <th scope="col">Task Type</th>
      <th scope="col">Account</th>
      <th scope="col">Task closure %</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let taskFilterData of taskFilterList;  let i=index;">
      <th scope="row">{{taskFilterData.task_id}}</th>
      <td  style="word-wrap: break-word;min-width: 15vw;max-width: 15vw;"><div>{{taskFilterData.task_name}}</div></td>
      <td>{{taskFilterData.first_name}} {{taskFilterData.last_name}}</td>
      <td>{{taskFilterData.user_assign_count}}</td>
      <td>{{taskFilterData.task_created * 1000 | date:'yyyy-MM-dd'}}</td>

      <td>{{taskFilterData.task_type}}</td>
      <td>{{taskFilterData.task_account}}</td>

      <td>{{taskFilterData.user_complete_count/taskFilterData.user_assign_count*100 | number:'1.0-0'}}%</td>
        <td>
          <img class="task_download" src="assets/task_manager_icons/download_task.svg" (click)="downloadTaskDetails(taskFilterData.task_id)">
     <img class="delete_icon" src="assets/task_manager_icons/delete.svg" (click)="deleteOpen(deleteContent,taskFilterData.task_id)"/>
      </td>
    </tr>

  </tbody>
</table>
</div>
</div>
</div>

<ng-template #deleteContent let-modal>
   <div class="modal-header">
      <h4 class="modal-title delete_title" id="modal-basic-title" >Delete Task</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span>&times;</span>
      </button>
   </div>
   <div class="modal-body">
      <div class="row" style="padding:15px;">
         <div class="col-md-12 col-sm-12 col-xs-12 text-center" style="margin-bottom: 15px;">
            <h4 class="delete_head" style="margin-top: 0px !important;">Are you sure you want to continue?</h4>
            <p class="debug-url"></p>
         </div>
         <div class="col-md-6 col-md-offset-6">
            <button type="button" class="btn btn-teel-green delete_btn" (click)="deleteTask()">Yes</button>
         </div>
         <div class="col-md-6">
            <button type="button" class="btn red full-btn-width cancel_btn" style="border-radius: 4px !important;" (click)="modal.close('Save click')">No</button>
         </div>
      </div>
   </div>
</ng-template>
</div>
</div>

